/// <reference path="../prepk2009.js" />
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import './AdminSettings.css';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Constants from '../../ApplicationConstants';
import CheckIcon from '@material-ui/icons/Check';
import { ExpandMore } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Table, TableContainer, TableRow, TableCell, TableHead, TableBody, TablePagination, TableSortLabel } from '@material-ui/core';

const subOptions = ['Product Release', 'Product Edition', 'PhoneKey ID', 'EULAType', 'Key Class', 'Sub Class'];

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const columnsProductProfile = [
    {
        id: 'productName',
        label: 'Product Name',
    },
    {
        id: 'hashContact',
        label: 'Contact',
        align: 'left',
        format: value => value.toLocaleString(),
    },
    {
        id: 'activationSystemName',
        label: 'Launch Type',
        align: 'left',
        format: value => value.toLocaleString(),
    },
    {
        id: 'usePKConfig',
        label: 'Use PKConfig',
        align: 'center',
        format: value => value.toLocaleString(),
    },
    {
        id: 'isActive',
        label: 'Is Active',
        align: 'center',
        format: value => value.toLocaleString(),
    },
];
const columnsValidationList = [
    {
        id: 'domainColumnValue',
        label: 'Validation Value',
    },
    {
        id: 'isActive',
        label: 'Is Active',
        align: 'center',
        format: value => value.toLocaleString(),
    },

];

async function submitProductProfile(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.SetProductProfile, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const submitProductProfileData = await response.json();
    return submitProductProfileData;
}

async function submitDomainValues(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.UpdateDomainValues, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const submitDomainValuesData = await response.json();
    return submitDomainValuesData;
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    array.forEach(function (object, index) {
        object.createdDate = new Date(object.createdDate).getTime();
    })
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                className="border"
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton className="border" onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                className="border"
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                className="border"
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function MainComponent(props) {

    const classes = useStyles();

    //const [file, setFile] = React.useState('');
    const [subMenu, setsubMenu] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [existingIndex, setExistingIndex] = React.useState(-1);
    const [selectedProduct, setSelectedProduct] = React.useState(true);
    const [productProfiles, setProductProfiles] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedProductValue, setSelectedProductValue] = React.useState('');
    const [validationItem, setValidationItem] = React.useState(props.productRelease);
    const [activationSystem, setActivationSystem] = React.useState('');
    const [alertBoxMessage, setAlertBoxMessage] = React.useState('');

    const [productIdProductProfile, setProductIdProductProfile] = React.useState(0);
    const [existingProductProfile, setExistingProductProfile] = React.useState(false);
    const [ProductNameProductProfile, setProductNameProductProfile] = React.useState('');
    const [HashContactProductProfile, setHashContactProductProfile] = React.useState('');
    const [LaunchTypeProductProfile, setLaunchTypeProductProfile] = React.useState('');
    const [UsePKConfigProductProfile, setUsePKConfigProductProfile] = React.useState(false);
    const [IsActiveProductProfile, setIsActiveProductProfile] = React.useState(false);
    const [activationSystemIdProductProfile, setActivationSystemIdProductProfile] = React.useState(0);

    const [domainDataId, setDomainDataId] = React.useState(0);
    const [excelMappingId, setExcelMappingId] = React.useState(0)
    const [existingValidationValue, setExistingValidationValue] = React.useState(false);
    const [DomainColumnValueValidationValue, setDomainColumnValueValidationValue] = React.useState('');
    const [IsActiveValidationValue, setIsActiveValidationValue] = React.useState(false);

    const [successfullProductProfile, setSuccessfullProductProfile] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('productName');
    const [updateFlag, setUpdateFlag] = React.useState(false)

    useEffect(() => {
        if (selectedIndex !== existingIndex) {
            if (selectedIndex === 0) {
                (async () => await getDomainDataValues(4))();
                setExcelMappingId(4);
            }
            if (selectedIndex === 1) {
                (async () => await getDomainDataValues(5))();
                setExcelMappingId(5);
            }
            if (selectedIndex === 2) {
                (async () => await getDomainDataValues(23))();
                setExcelMappingId(23);
            }
            if (selectedIndex === 3) {
                (async () => await getDomainDataValues(31))();
                setExcelMappingId(31);
            }
            if (selectedIndex === 4) {
                (async () => await getDomainDataValues(58))();
                setExcelMappingId(58);
            }
            if (selectedIndex === 5) {
                (async () => await getDomainDataValues(71))();
                setExcelMappingId(71);
            }
            if (selectedIndex === 6) {
                (async () => await getDomainDataValues(73))();
                setExcelMappingId(73);
            }

            setOpenLoading(false);
        }
        if (!updateFlag && productProfiles.length === 0 && props.productProfiles && props.productProfiles.length > 0) {
            setProductProfiles(props.productProfiles);
            setUpdateFlag(true);
        }
    })

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleListItemClick = (event, index) => {
        setExistingIndex(selectedIndex);
        setSelectedIndex(index);
        setSelectedProduct(false);
    };
    const handleValidationClick = () => {
        console.log("Validation Profile Clickd");
        setsubMenu(!subMenu);
        setSelectedIndex(-1);
    };

    const handleProductClick = () => {
        setSelectedProduct(true);
        setSelectedIndex(-1);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenLoading(false);
        setdefaults();
    };
    const setdefaults = () => {
        setIsActiveProductProfile(false);
        setUsePKConfigProductProfile(false);
        setLaunchTypeProductProfile('');
        setIsActiveValidationValue(false);
    };

    const handleNewProductProfile = () => {
        setExistingProductProfile(false);
        setOpenDialog(true);
    };

    const handleNewValidationValue = () => {
        setExistingValidationValue(false);
        setOpenDialog(true);
    };

    const handleExistingProductProfile = (row) => {
        setExistingProductProfile(true);
        setProductIdProductProfile(row.productId);
        setActivationSystemIdProductProfile(row.activationSystemId);
        setProductNameProductProfile(row.productName);
        setHashContactProductProfile(row.hashContact);
        setLaunchTypeProductProfile(row.activationSystemName);
        setUsePKConfigProductProfile(row.usePKConfig);
        setIsActiveProductProfile(row.isActive);
        setOpenDialog(true);
    };

    const handleExistingValidationValue = (row) => {
        setExistingValidationValue(true);
        setDomainDataId(row.domainDataId);
        setExcelMappingId(row.excelMappingId);
        setDomainColumnValueValidationValue(row.domainColumnValue);
        setIsActiveValidationValue(row.isActive);
        setOpenDialog(true);
    };

    const handleActivationSystemChange = (event) => {
        setActivationSystem(event.target.value);
        setLaunchTypeProductProfile(event.target.value);
        if (!existingProductProfile) {
            setActivationSystemIdProductProfile(event.target.value);
        }
    }
    const getProductProfiles = async () => {
        setOpenLoading(true);
        const response = await fetch(ApplicationConstants.GetProductProfiles, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        if (data && data.length > 0) {
            setProductProfiles(data);
        }
        setOpenLoading(false);
    }

    const getDomainDataValues = async (excelMappingId) => {
        setOpenLoading(true);
        const response = await fetch(ApplicationConstants.GetDomainDataValues, {
            method: 'POST',
            body: (excelMappingId),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        if (data && data.length > 0) {
            setValidationItem(data);
        }
    }

    const handleProductProfileSave = async (event) => {
        setOpenDialog(false);
        //Loading Start
        setOpenLoading(true);
        if (existingProductProfile) {
            var payload = {
                "ProductId": productIdProductProfile,
                "ProductName": ProductNameProductProfile,
                "ActivationSystemId": activationSystemIdProductProfile,
                "ActivationSystemName": LaunchTypeProductProfile,
                "IsActive": IsActiveProductProfile,
                "UsePKConfig": UsePKConfigProductProfile,
                "HashContact": HashContactProductProfile,
            }
            console.log(payload);
        }
        else {
            var payload = {
                "ProductId": null,
                "ProductName": ProductNameProductProfile,
                "ActivationSystemId": activationSystemIdProductProfile,
                "ActivationSystemName": LaunchTypeProductProfile,
                "IsActive": IsActiveProductProfile,
                "UsePKConfig": UsePKConfigProductProfile,
                "HashContact": HashContactProductProfile,
            }
            console.log(payload);
        }
        if (payload.ActivationSystemId == 2 && payload.HashContact == '') {
            setAlertBoxMessage("Contact must be specified when Launch Type is set to HASH.");
            setOpenAlert(true);
            setSuccessfullProductProfile(false);
        } else {
            var response = await submitProductProfile(payload, props.accountInfo.idToken, props.accountInfo.accessToken);
            if (response.returnCode === 0) {
                setAlertBoxMessage(response.returnMessage === "" ? Constants.AlertMessages.ActionCompleted : response.returnMessage);
                setOpenAlert(true);
                setSuccessfullProductProfile(true);
            }
            else {
                setAlertBoxMessage(response.returnMessage === "" ? Constants.AlertMessages.ActionCompleted : response.returnMessage);
                setOpenAlert(true);
                setSuccessfullProductProfile(false);
            }
            await getProductProfiles();
        }
        setOpenLoading(false);
        setdefaults();
    }

    const handleDomainValuesSave = async (event) => {
        setOpenDialog(false);
        setOpenLoading(true);
        if (existingValidationValue) {
            var payload = {
                "DomainDataId": domainDataId,
                "ExcelMappingId": excelMappingId,
                "DomainColumnValue": DomainColumnValueValidationValue,
                "IsActive": IsActiveValidationValue,
            }
        }
        else {
            var payload = {
                "DomainDataId": null,
                "ExcelMappingId": excelMappingId,
                "DomainColumnValue": DomainColumnValueValidationValue,
                "IsActive": IsActiveValidationValue,
            }
        }
        console.log(payload);
        var response = await submitDomainValues(payload, props.accountInfo.idToken, props.accountInfo.accessToken);
        setOpenLoading(false);
        setdefaults();
        if (response.returnCode === 0) {
            setAlertBoxMessage(response.returnMessage);
            setOpenAlert(true);
            setSuccessfullProductProfile(true);
        }
        else {
            setAlertBoxMessage(response.returnMessage);
            setOpenAlert(true);
            setSuccessfullProductProfile(false);
        }
        await getDomainDataValues(excelMappingId);
    }

    const handleAlertClose = () => {
        if (successfullProductProfile) {
            setOpenAlert(false);
        } else {
            setOpenAlert(false);
        }
    };

    const AlertDialog = (
        <Dialog
            className="borderAlert"
            open={openAlert}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullProductProfile ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{"Product Profile"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{alertBoxMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAlertClose} color="primary" autoFocus>
                    Close
                    </Button>
            </DialogActions>
        </Dialog>)

    const ProductProfileDialog = (<React.Fragment>
        <Dialog
            onClose={handleClose} id="DialogBox" open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogTitle id="alert-dialog-title">
                <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} />Edit Product Profile
            </DialogTitle>
            <DialogContent dividers>
                <React.Fragment>
                    <FormGroup column>
                        <FormGroup row>
                            < TextField
                                value={existingProductProfile ? ProductNameProductProfile : null}
                                margin="dense"
                                disabled={existingProductProfile}
                                id="productName"
                                label="Product Name"
                                type="email"
                                onChange={(event) => setProductNameProductProfile(event.target.value)}
                                fullWidth
                            />
                            < TextField
                                value={existingProductProfile ? HashContactProductProfile : null}
                                margin="dense"
                                id="hashContact"
                                label="Contact"
                                type="email"
                                required={activationSystemIdProductProfile == 2 ? true : false}
                                helperText={activationSystemIdProductProfile == 2 ? "Hash Contact is mandatory." : null}
                                onChange={(event) => setHashContactProductProfile(event.target.value)}
                                fullWidth
                            />
                        </FormGroup>
                        <FormGroup row>
                            <TextField
                                id="launchType"
                                select
                                label="Launch Type"
                                value={LaunchTypeProductProfile}
                                onChange={handleActivationSystemChange}
                                helperText="Please select the Launch Type">
                                key={activationSystem}
                                {props.activationSystemList.map(activationSystem => (
                                    <MenuItem key={activationSystem.activationSystemId} value={existingProductProfile ? activationSystem.activationSystemName : activationSystem.activationSystemId}>
                                        {activationSystem.activationSystemName}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormGroup column>
                                <FormControlLabel id="CheckBoxes"
                                    control={
                                        <Checkbox
                                            checked={IsActiveProductProfile}
                                            color="primary"
                                            onChange={(event) => {
                                                setIsActiveProductProfile(event.target.checked)
                                            }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    }
                                    label="Is Active"
                                />
                                <FormControlLabel id="CheckBoxes"
                                    control={
                                        <Checkbox
                                            checked={UsePKConfigProductProfile}
                                            color="primary"
                                            onChange={(event) => {
                                                setUsePKConfigProductProfile(event.target.checked)
                                            }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    }
                                    label="Use PKConfig"
                                />
                            </FormGroup>
                        </FormGroup>
                    </FormGroup>
                </React.Fragment>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleProductProfileSave} variant='contained' id='ButtonStyle' disabled={activationSystemIdProductProfile == 2 && HashContactProductProfile == '' ? true : false}>
                    Save
                    </Button>
                <Button onClick={handleClose} variant='contained' id='ButtonStyle'>
                    Cancel
                    </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
    )

    const ValidationValuesDialog = (
        <React.Fragment>
            <Dialog
                onClose={handleClose} id="DialogBox" open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">
                    <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} />Edit Validation</DialogTitle>
                <DialogContent dividers>
                    <FormGroup row>
                        <FormControlLabel id="CheckBoxes"
                            control={

                                <TextField
                                    autoFocus
                                    disabled={existingValidationValue}
                                    margin="dense"
                                    id="name"
                                    label="New Value"
                                    type="email"
                                    value={existingValidationValue ? DomainColumnValueValidationValue : null}
                                    onChange={(event) => setDomainColumnValueValidationValue(event.target.value)}
                                    fullWidth
                                />
                            }
                        />

                        <FormControlLabel id="CheckBoxes"
                            control={
                                <Checkbox
                                    checked={IsActiveValidationValue}
                                    color="primary"
                                    onChange={(event) => {
                                        setIsActiveValidationValue(event.target.checked)
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            }
                            label="Is Active"
                        />

                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDomainValuesSave} variant='contained' id='ButtonStyle'>
                        Save
                    </Button>
                    <Button onClick={handleClose} variant='contained' id='ButtonStyle'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

    const DisplayTable = (
        <React.Fragment>
            <TableContainer id="container">
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            {selectedProduct ?
                                columnsProductProfile.map(column => (
                                    <TableCell
                                        id="TableHeader-adminSettings"
                                        key={column.id}
                                        sortDirection={orderBy === column.id ? order : false}>
                                        {column.id == 'productName' || column.id == 'activationSystemName' ?
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={createSortHandler(column.id)}
                                            >

                                                {column.label}
                                                {orderBy === column.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel> :
                                            column.label
                                        }
                                    </TableCell>
                                )) : columnsValidationList.map(column => (
                                    <TableCell
                                        id="TableHeader-adminSettings"
                                        key={column.id}
                                        sortDirection={orderBy === column.id ? order : false}>
                                        {column.id == 'domainColumnValue' ?
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={createSortHandler(column.id)}
                                            >

                                                {column.label}
                                                {orderBy === column.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel> :
                                            column.label
                                        }
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedProduct ? (rowsPerPage > 0
                            ? stableSort(productProfiles, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : productProfiles
                        ).map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.projectActivityLogID} onClick={event => handleExistingProductProfile(row)}>
                                    {columnsProductProfile.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell id='productProfileBody' key={column.id} align={column.align} >
                                                {column.format && typeof value === 'boolean' && value === true ? <CheckIcon /> : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }) : (validationItem && validationItem.length > 0) ? (rowsPerPage > 0
                            ? stableSort(validationItem, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : validationItem
                        ).map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.projectActivityLogID} onClick={event => handleExistingValidationValue(row)}>
                                    {columnsValidationList.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell id='productProfileBody' key={column.id} align={column.align}>
                                                {column.format && typeof value === 'boolean' && value ? <CheckIcon /> : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className="border"
                rowsPerPageOptions={[20, 30, 40, { label: 'All', value: -1 }]}
                colSpan={5}
                count={selectedProduct ? (productProfiles ? productProfiles.length : 0) : (validationItem ? validationItem.length : 0)}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            {selectedProduct ? ProductProfileDialog : ValidationValuesDialog}
            {AlertDialog}
            <Backdrop className={classes.backdrop} open={openLoading} >
                <CircularProgress />
            </Backdrop>


        </React.Fragment>);

    return (
        <React.Fragment>
            <header role="heading" className="Header" aria-level="1">
                <h1>Admin Settings</h1>
                <h1 id="SubHeading">{props.productName}</h1>
            </header>
            <div id="cards">
                <div id="sideblock-adminSettings">
                    <Card id="MenuCard">
                        <CardHeader id="CardHeader" />
                        <CardContent>
                            <List id="PrimaryListStyle">
                                <li>
                                <ListItem button onClick={handleProductClick} >
                                    <ListItemText id="MenuOptions" primary="Product Profiles" />
                                    </ListItem>
                                </li>
                                <li>
                                <ListItem button onClick={handleValidationClick}>
                                    <ListItemText id="MenuOptions" primary="Validation Values" />
                                    {subMenu ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <li>
                                <Collapse in={subMenu} timeout="auto" style={{ marginLeft: '2em' }} unmountOnExit>
                                    <List id="SecondaryListStyle" >
                                        <li>
                                        {subOptions.map((option, index) =>
                                            <ListItem button selected={selectedIndex === index} onClick={event => handleListItemClick(event, index)}>
                                                <ListItemText id="MenuOptions" primary={option} />
                                            </ListItem>)}
                                            </li>
                                    </List>
                                    </Collapse>
                                    </li>
                            </List>
                        </CardContent>
                    </Card>
                    <Button id="ButtonStyle" variant="contained" color="primary" style={{ marginBottom: '8em', width: '50%', alignSelf: 'center' }} onClick={selectedProduct ? handleNewProductProfile : handleNewValidationValue}>Add New</Button>
                </div>
                <Card id="DisplayCard">
                    {DisplayTable}

                </Card>
            </div>
        </React.Fragment>
    );
}

var ApplicationConstants = {};
MainComponent.propTypes = {
    productProfiles: PropTypes.array,
    setNav: PropTypes.func,
    activationSystemList: PropTypes.func,
    accountInfo: PropTypes.object,
    dataUpdated: PropTypes.bool
};

export class AdminSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productProfiles: [],
            activationSystemList: [],
            setNav: this.props.setNav,
            accountInfo: props.accountInfo
        };
    }

    async getProductProfiles() {
        const response = await fetch(ApplicationConstants.GetProductProfiles, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ productProfiles: data })
    }

    async getActivationSystemList() {
        const response = await fetch(ApplicationConstants.GetActivationSystemLists, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ activationSystemList: data })
    }

    async getDomainDataValues(excelMappingId) {
        const response = await fetch(ApplicationConstants.GetDomainDataValues, {
            method: 'POST',
            body: (excelMappingId),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        return data;
    }

    componentWillMount() {
        ApplicationConstants = Constants.EndPoints[this.props.versionDetail];
    }
    async componentDidMount() {
        await this.getProductProfiles();
        await this.getActivationSystemList();
    }

    render() {
        return (
            <React.Fragment>
                <MainComponent {...this.state} />
            </React.Fragment>
        );
    }
}


