import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './NewProject.css';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {  Backdrop, Button , CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControl, TextField,} from '@material-ui/core';
import Constants from '../../ApplicationConstants';


const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

var ApplicationConstants = {};

function ProjectLaunch(props) {

    const classes = useStyles();
    const [productName, setProductName] = React.useState('');
    const [productVersion, setProductVersion] = React.useState('');
    const [productRelease, setProductRelease] = React.useState('');
    const [PlannedStartDate, setPlannedStartDate] = React.useState('');
    const [PlannedBaselineDate, setPlannedBaselineDate] = React.useState('');
    const [PlannedReleaseDate, setPlannedReleaseDate] = React.useState('');
    const [Notes, setNotes] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);
    const [alertBoxMessage, setAlertBoxMessage] = React.useState('');
    const [successfullLaunch, setSuccessfullLaunch] = React.useState(false);


    const handleCancel = () => {

        props.setNav(3);
      
       // setProductName('');
       // setProductVersion('');
       // setProductRelease('');
    }

    const handleSave = async () => {
         
        setOpenLoading(true);
        var submitProjectResponse = await submitProjectLaunch({ productName, productVersion, productRelease, PlannedStartDate, PlannedBaselineDate, PlannedReleaseDate, Notes, SubClass: props.versionDetail }, props.accountInfo.idToken, props.accountInfo.accessToken);
        setOpenLoading(false);
         if (submitProjectResponse.returnCode === 0) {
            setAlertBoxMessage(submitProjectResponse.returnMessage);
            setOpenAlert(true);
            setSuccessfullLaunch(true);
        }
        else {
            setAlertBoxMessage(submitProjectResponse.returnMessage);
            setOpenAlert(true);
            setSuccessfullLaunch(false);
        }
    }

    const handleClose = () => {
        if (successfullLaunch) {
            props.setNav(3);
        } else {
            setOpenAlert(false);
        }
    };

    return (
        <section className='newproject-section'>
            <header role="heading" className="Header" aria-level="1">
                <h1>New Product Launch</h1>
            </header>
            <h2>First, fill out the details below.</h2>

            <FormControl className="productSelectForm">
                <FormGroup>
                    <label for="productName" className="productSelectFormLabel">Product Name (*)</label>
                    <Autocomplete
                        id="productName"
                        options={props.productProfiles}
                        getOptionLabel={option => option.productName}
                        onSelect={(event) => setProductName(event.target.value)}
                        renderInput={params => (
                            <TextField {...params}                
                                label="Select Product Name" variant="outlined" fullWidth />
                        )}
                    />
                </FormGroup>
                <FormGroup>
                    <label for="enterVersion" className="productSelectFormLabel">Version (*)</label>
                    <TextField id="enterVersion" label="Enter Version" className="" onChange={(event) => setProductVersion(event.target.value)} variant="outlined" fullWidth />
                </FormGroup>
                <FormGroup>
                    <label for="selectRelease" className="productSelectFormLabel">Release (*)</label>
                    <Autocomplete
                        id="selectRelease"
                        options={props.domainDataValues}
                        getOptionLabel={option => option.domainColumnValue}
                        onSelect={(event) => setProductRelease(event.target.value)}
                        renderInput={params => (
                            <TextField {...params} label="Select Release" variant="outlined" fullWidth />
                        )}
                    />
                </FormGroup>
                <div id="NavigationContainer">
                    <Button size="large" id="PPPButton1" variant="contained" color="primary" onClick={handleCancel} startIcon={<CancelIcon />}>Cancel</Button>
                    <Button size="large" id="PPPButton2" variant="contained" disabled={(productName === "" || productVersion === "" || productRelease === "")} color={(productName === "" || productVersion === "" || productRelease === "") ? "secondary" : "primary"} onClick={handleSave} startIcon={<SaveIcon />}>Save</Button>
                </div>
            </FormControl>
            <Backdrop className={classes.backdrop} open={openLoading} >
                <CircularProgress />
            </Backdrop>
            <Dialog
                open={openAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullLaunch ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{"New Product Launch"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{alertBoxMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    );
}

ProjectLaunch.propTypes = {
    productProfiles: PropTypes.array,
    domainDataValues: PropTypes.array,
    setNav: PropTypes.func,
    accountInfo: PropTypes.object
};

async function submitProjectLaunch(data, idToken, accessToken) {
    console.log(data);
    const response = await fetch(ApplicationConstants.NewProjectLaunch, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    
    const projectLaunchData = await response.json();
    return projectLaunchData;
}

export class NewProject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productProfiles: [],
            domainDataValues: [],
            setNav: this.props.setNav,
            accountInfo: props.accountInfo,
            versionDetail: this.props.versionDetail == "SubClass3" ? "ProductKey3" : "ProductKey6"
        };
    }

    componentWillMount() {
        ApplicationConstants = Constants.EndPoints[this.props.versionDetail];
    }

    async getProductProfiles() {
        const response = await fetch(ApplicationConstants.GetProductProfiles, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        var productProfiles = [];
        if (data.length > 0 && Array.isArray(data)) {
            data.forEach(function (productProfile) {
                if (productProfile.isActive === true) {
                    productProfiles.push(productProfile);
                }
            })
        }    
        this.setState({ productProfiles: productProfiles })
    }

    async getDomainDataValues(excelMappingId) {
        const response = await fetch(ApplicationConstants.GetDomainDataValues, {
            method: 'POST',
            body: (excelMappingId),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        var domainDataValues = [];
        if (data.length > 0 && Array.isArray(data)) {
            data.forEach(function (domainDataValue) {
                if (domainDataValue.isActive === true) {
                    domainDataValues.push(domainDataValue);
                }
            })
        }
        this.setState({ domainDataValues: domainDataValues });
    }

    componentDidMount() {
        this.getProductProfiles();
        this.getDomainDataValues(4);
    }

    render() {
        return (
            <div>
                <ProjectLaunch {...this.state} />
            </div>
        );
    }
}