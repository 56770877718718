import React, { useEffect, useRef, DependencyList } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, IconButton, InputLabel, FormGroup, FormHelperText, TextField, Divider, FormControl, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Icon, Collapse, CardContent, CardHeader, Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as XLSX from 'xlsx';
import './Reallocate.css';
import '../NavBar';
import Select from '@material-ui/core/Select';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from '@material-ui/core/Fab';
import { Container, Row, Col } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Constants from '../../ApplicationConstants';
import $ from 'jquery'; 

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: 440,
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    pkpnTable: {
        width: '80%',
    },
    input: {
        display: 'none',
    },
}));

const columns = [

    {
        id: 'pkpn',
        label: 'PKPN',
        minWidth: 170
    },
    {
        id: 'keyType',
        label: 'KEY Type',
        minWidth: 110,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'lifetimeForecast',
        label: 'Lifetime Forecast',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'uniqueNumberStart',
        label: 'Start',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'uniqueNumberEnd',
        label: 'End',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },

    {
        id: 'pkpnDescription',
        label: 'PKPN Description',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },

];


function ReallocateCard(props) {

    
    const refData = React.useRef(props.data);
    const classes = useStyles();
    const [AlertMessage, setAlertMessage] = React.useState('');
    const [openSourcePKPNBox, setOpenSourcePKPNBox] = React.useState(false);
    const [openDestinationPKPNBox, setOpenDestinationPKPNBox] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);

  
    const [validData, setValidData] = React.useState(false);

    const [lifeTime, setLifeTime] = React.useState(0);
    const [transfer, setTransfer] = React.useState(0);
    const [transferError, setTransferError] = React.useState({ value: false, Alert: "" });
    const refTransfer = React.useRef(transfer);
    const [openLoading, setOpenLoading] = React.useState(props.loading);

    const [lowerRange, setLowerRange] = React.useState(0);
    const [lowerRangeError, setLowerRangeError] = React.useState({ value: false, Alert: "" });

    const [generated, setGenerated] = React.useState(0);

    const [checkValue, setCheckValue] = React.useState(0);
    const refCheck = React.useRef(checkValue);

    const [destinationLaunchQuantity, setDestinationLaunchQuantity] = React.useState(0);
    const [destinationLaunchQuantityError, setDestinationLaunchQuantityError] = React.useState({ value: false, Alert: "" });
    const refdestinationLaunchQuantity = React.useRef(destinationLaunchQuantity);

    const [fromPKPN, setFromPKPN] = React.useState({
        groupId: 0,
        rowId: " ",
        pkpn: "",
        pkpnDescription: "",
        lifetimeForecast: 0,
        uniqueNumberStart: 0,
        uniqueNumberEnd: 0,
        keyType: "",
        launchQuantity: 0
    });
    const refFromPKPN = React.useRef(fromPKPN);
    const [fromPKPNError, setFromPKPNError] = React.useState({ value: false, Alert: "" });

    const [toPKPN, setToPKPN] = React.useState(
        {
            groupId: 0,
            rowId: " ",
            pkpn: "",
            pkpnDescription: "",
            lifetimeForecast: 0,
            uniqueNumberStart: 0,
            uniqueNumberEnd: 0,
            keyType: "",
            launchQuantity: 0
        });
    const refToPKPN = React.useRef(toPKPN);
    const [toPKPNError, setToPKPNError] = React.useState({ value: false, Alert: "" });

    const PKPNList = Array.from(new Set(props.UIDisplay.pkpnRangeCollection.map(option => option.pkpn)));
    const DestPKPNList = Array.from(new Set(props.UIDisplay.pkpnRangeCollection.map(option => option.pkpn)));
    DestPKPNList.splice(0, 0, "New PKPN");

    //FromPKPN effect
    React.useEffect(() => {
        props.data.isValid = false;
        var tempTransfer = transfer;
        setTransfer(0);
        setTransfer(tempTransfer);
        tempTransfer = destinationLaunchQuantity
        setDestinationLaunchQuantity(0);
        setDestinationLaunchQuantity(tempTransfer);
        setToPKPN({ ...toPKPN });
        setLifeTime(fromPKPN.lifetimeForecast);
        setCheckValue(0);
        if (transfer > 0)
            setLowerRange(lifeTime - transfer);
        setValidData(false);

    }, [fromPKPN]);


    React.useEffect(() => {
        setValidData(false);
        props.data.isValid = false;
        console.log(fromPKPN);
        setCheckValue(0);
        if (toPKPN.pkpn === fromPKPN.pkpn && fromPKPN.pkpn !== "") {
            setFromPKPNError({ value: true, Alert: Constants.AlertMessages.Reallocation_PKPNConflict });
            setToPKPNError({ value: true, Alert: Constants.AlertMessages.Reallocation_PKPNConflict });
        }
        else {
            setFromPKPNError({ value: false, Alert: "" });
            setToPKPNError({ value: false, Alert: "" });
        }
        if (fromPKPN.pkpn !== "") {
            props.UIDisplay.pkpnRangeCollection.map(row => {
                if (row.pkpn === toPKPN.pkpn) {
                    if (row.groupId !== fromPKPN.groupId) {
                        setToPKPNError({ value: true, Alert: Constants.AlertMessages.Reallocation_GroupIDError });
                    }
                    else {
                        if (row.uniqueNumberEnd > fromPKPN.uniqueNumberStart) {
                            setToPKPNError({ value: true, Alert: Constants.AlertMessages.Reallocation_RangeError });
                        }
                        else {
                            setToPKPNError({ value: false, Alert: "" });
                        }
                    }
                }
            }
            );
        }
    }, [toPKPN]);
    //List of all unique PKPN's
    React.useEffect(() => {

        setCheckValue(lifeTime - transfer - lowerRange - generated);
    }, [checkValue]);

    React.useEffect(() => {
        setValidData(false);
        props.data.isValid = false;
        setLowerRange(lifeTime - transfer);
        setCheckValue(0);

        if (transfer > lifeTime) {
            setTransferError({ value: true, Alert: Constants.AlertMessages.Reallocation_TransferError });

        }
        else {
            setTransferError({ value: false, Alert: Constants.AlertMessages.Reallocation_LaunchQuantityError });
        }

    }, [transfer]);

    React.useEffect(() => {
        if (props.loading !== openLoading) {
            setOpenLoading(props.loading)
        }
    })

    React.useEffect(() => {
        setValidData(false);
        props.data.isValid = false;
        setCheckValue(0);
        console.log(destinationLaunchQuantity);
        if (transfer - destinationLaunchQuantity < 0) {
            setDestinationLaunchQuantityError({ value: true, Alert: Constants.AlertMessages.Reallocation_LaunchQuantityError });

        }
        else {
            setDestinationLaunchQuantityError({ value: false, Alert: " " });
        }


    }, [destinationLaunchQuantity]);

    React.useEffect(() => {
        console.log("CheckPoint")
        if (!validData && !transferError.value && !fromPKPNError.value && !toPKPNError.value && !destinationLaunchQuantityError.value && !lowerRangeError.value && checkValue === 0 && fromPKPN.rowId != "" && transfer != 0 && transfer != "") {
            setValidData(true);
            console.log("CheckPoint")
        }
    }, [transferError, fromPKPNError, toPKPNError, destinationLaunchQuantityError, lowerRangeError, checkValue]);

    React.useEffect(() => {
        console.log("CheckPoint")
        if (validData) {
            console.log("CheckPoint")

            props.data.cardSource.PKPN = fromPKPN.pkpn;
            props.data.cardSource.GroupId = 0;
            props.data.cardSource.LifetimeForecast = fromPKPN.lifetimeForecast - transfer;
            props.data.cardSource.IsNewPKPN = false;
            props.data.cardSource.UniqueNumberEnd = fromPKPN.uniqueNumberEnd - transfer;
            props.data.cardSource.UniqueNumberStart = fromPKPN.uniqueNumberStart;
            props.data.cardSource.LaunchQuantity = typeof fromPKPN.launchQuantity === 'undefined' ? 0 : fromPKPN.launchQuantity - transfer;
            props.data.cardSource.RowId = fromPKPN.rowId;


            props.data.cardDestination.PKPN = toPKPN.pkpn;
            props.data.cardDestination.GroupId = 0;
            props.data.cardDestination.LifetimeForecast = transfer;
            props.data.cardDestination.IsNewPKPN = toPKPN.pkpn === "" ? true : false;
            props.data.cardDestination.UniqueNumberEnd = fromPKPN.uniqueNumberEnd;
            props.data.cardDestination.UniqueNumberStart = fromPKPN.uniqueNumberEnd - transfer + 1
            props.data.cardDestination.LaunchQuantity = toPKPN.destinationLaunchQuantity === 0 ? transfer : destinationLaunchQuantity;
            props.data.cardDestination.Descriptor = "";
            props.data.cardDestination.ProgramPurpose = "";
            props.data.isValid = validData;

            setValidData(false);

        }
    }, [validData]);

    React.useEffect(() => {
        setCheckValue(0);
    }, [generated]);


    const handleCloseDialog = () => {
        setOpenSourcePKPNBox(false);
        setOpenDestinationPKPNBox(false);
    }


    const PKPNEntered = (event) => {

        var uniqueStart = -1;
        var select;
        props.UIDisplay.pkpnRangeCollection.map((row) => {

            if (row.pkpn === event && row.uniqueNumberStart > uniqueStart) {
                uniqueStart = row.uniqueNumberStart;
                select = row;
            }
        });
        var row = select === null ? refFromPKPN.current : select;
        setFromPKPN({
            groupId: row.groupId,
            rowId: row.rowId,
            pkpn: row.pkpn,
            pkpnDescription: row.pkpnDescription,
            lifetimeForecast: row.lifetimeForecast,
            uniqueNumberStart: row.uniqueNumberStart,
            uniqueNumberEnd: row.uniqueNumberEnd,
            keyType: row.keyType,
            launchQuantity: row.LaunchQuantity
        });
        console.log(fromPKPN);
    }



    const DestinationPKPNBox = (
        <div >
            <Dialog id="PKPNDetails" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDestinationPKPNBox} >
                <DialogTitle id="customized-dialog-title" >
                    <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> Destination PKPN Details
                 </DialogTitle>
                <DialogContent dividers>
                    <TableContainer classeName="container">
                        <Table stickyHeader aria-label="sticky table">

                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            id="TableHeader"
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow >
                                    {columns.map(column => {
                                        const value = toPKPN[column.id];
                                        if (column.id === "pkpn" && value == "")
                                            return (
                                                <TableCell id='ActivityStatusTableBody' key={column.id} align={column.align}>
                                                    New PKPN
                                                </TableCell>
                                            );
                                        return (
                                            <TableCell id='ActivityStatusTableBody' key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>

                            </TableBody>
                            <h6>New row details</h6>
                            <h7> Enter values to see the details</h7>
                        </Table>

                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDialog} color="primary">
                        Close
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    const SourcePKPNBox = (
        <div >
            <Dialog id="PKPNDetails" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openSourcePKPNBox} >
                <DialogTitle id="customized-dialog-title" >
                    <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> Source PKPN Details
                 </DialogTitle>
                <DialogContent dividers>
                    {fromPKPN.pkpn !== "" ? <TableContainer classeName="container">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            id="TableHeader"
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow >
                                    {columns.map(column => {
                                        const value = fromPKPN[column.id];
                                        return (
                                            <TableCell id='ActivityStatusTableBody' key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>

                            </TableBody>

                        </Table>

                    </TableContainer> : <h2>Select PKPN to view Details</h2>}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDialog} color="primary">
                        Close
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );



    return (
        <Card id="Cardmargin" >
            <CardHeader id="CardHeader"
                avatar={!validData ? <h6 >Enter Details</h6> : <CheckCircleIcon style={{ color: "#FFFFFF" }} />}
                action={
                    <section id="paddingBottom">

                        <IconButton className="paddingBottom2" aria-label="settings" onClick={() => {
                            console.log(props.index)
                            props.delete(props.index)
                        }}>
                            <DeleteIcon style={{ color: '#fff' }} />
                        </IconButton>
                        <IconButton className="paddingBottom2" aria-label="settings" onClick={() => {
                            console.log(props.index)
                            props.refresh(props.index)
                        }}>
                            <RefreshIcon style={{ color: '#fff' }} />
                        </IconButton>
                    </section>} />
            <CardContent>
                <div id="SetPKPN">
                    <FormGroup id="PKPNValue">
                        <label for="endSerialNumber" className="productSelectFormLabel">Source PKPN (*)</label>

                        <Tooltip arrow placement="top" open={fromPKPNError.value} title={<h6>{fromPKPNError.Alert}</h6>}>


                            <Autocomplete
                                id="AutoComplete"

                                value={fromPKPN.pkpn}
                                options={PKPNList}
                                onChange={(event, newValue) => {

                                    if (PKPNList.indexOf(newValue) !== -1 || newValue === "")
                                        PKPNEntered(newValue);
                                }}
                                renderInput={(params) => <TextField id="AutoComplete" error={fromPKPN.value} {...params} label="Enter Source PKPN" fullWidth />}
                            />
                        </Tooltip>
                        <Button id="ShowDetailsButton" onClick={() => { setOpenSourcePKPNBox(true) }} >Show Details</Button>
                    </FormGroup>

                    <ArrowForwardIcon id="ForwardIcon" />
                    <FormGroup id="PKPNValue">
                        <label for="endSerialNumber" className="productSelectFormLabel">Destination PKPN (*)</label>
                        <Tooltip arrow placement="top" open={toPKPNError.value} title={<h6>{toPKPNError.Alert}</h6>} >
                            <Autocomplete

                                id="AutoComplete"
                                value={toPKPN.pkpn}
                                options={DestPKPNList}
                                onChange={(event, newValue) => {
                                    var pkpn;
                                    if (newValue === "New PKPN")
                                        pkpn = ""
                                    else
                                        pkpn = newValue;
                                    if (fromPKPN.rowId != "")
                                        setToPKPN({
                                            groupId: fromPKPN.groupId,
                                            rowId: "",
                                            pkpn: pkpn,
                                            pkpnDescription: "",
                                            lifetimeForecast: transfer,
                                            uniqueNumberStart: fromPKPN.uniqueNumberEnd - transfer + 1,
                                            uniqueNumberEnd: fromPKPN.uniqueNumberEnd,
                                            keyType: fromPKPN.keyType,
                                            launchQuantity: destinationLaunchQuantity
                                        });
                                    else
                                        setToPKPN({
                                            groupId: "-",
                                            rowId: "",
                                            pkpn: pkpn,
                                            pkpnDescription: "",
                                            lifetimeForecast: transfer,
                                            uniqueNumberStart: "-",
                                            uniqueNumberEnd: "-",
                                            keyType: "-",
                                            launchQuantity: destinationLaunchQuantity
                                        });
                                }
                                }
                                renderInput={(params) => <TextField error={toPKPNError.value} id="AutoComplete" {...params} label="Enter Destination PKPN" fullWidth />}
                            />
                        </Tooltip>
                        <Button id="ShowDetailsButton2" onClick={() => { setOpenDestinationPKPNBox(true) }} >Show Details</Button>
                    </FormGroup>
                </div>

                <div id="SetValues">

                    <FormGroup>
                        <label for="startSerialNumber" className="productSelectFormLabel">LifeTime Forecast</label>
                        <TextField type="number" id="startSerialNumber" value={lifeTime} className="" variant="outlined" onChange={(event) => { setLifeTime(event.target.value) }} fullWidth />

                    </FormGroup>
                    <FormGroup>
                        <label for="endSerialNumber" className="productSelectFormLabel">Transfer</label>
                        <Tooltip arrow open={transferError.value} title={<h6>{transferError.Alert}</h6>}>
                            <TextField type="number" error={transferError.value} id="endSerialNumber" value={transfer} className="" variant="outlined" onChange={(event) => {
                                setTransfer(event.target.value);
                            }} fullWidth />
                        </Tooltip>
                    </FormGroup>

                    <FormGroup>
                        <label for="startSerialNumber" className="productSelectFormLabel">Generated</label>
                        <TextField type="number" value={generated} className="" variant="outlined" onChange={(event) => {
                            setGenerated(event.target.value)

                        }} fullWidth />

                    </FormGroup>
                    <FormGroup>
                        <label className="productSelectFormLabel">Lower Range</label>
                        <Tooltip arrow open={lowerRangeError.value} title={<h6>{lowerRangeError.Alert}</h6>}>
                            <TextField type="number" error={lowerRangeError.value} id="endSerialNumber" value={lowerRange} className="" variant="outlined" onChange={(event) => {
                                setLowerRange(event.target.value);
                                setCheckValue(lifeTime - transfer - lowerRange);
                            }} fullWidth />
                        </Tooltip>
                    </FormGroup>
                    <FormGroup>
                        <label className="productSelectFormLabel">Destination Launch Quantity</label>
                        <Tooltip arrow open={destinationLaunchQuantityError.value} title={<h6>{destinationLaunchQuantityError.Alert}</h6>}>
                            <TextField type="number" error={destinationLaunchQuantityError.value} id="endSerialNumber" value={destinationLaunchQuantity} className="" variant="outlined" onChange={(event) => {
                                setDestinationLaunchQuantity(event.target.value)
                                console.log(destinationLaunchQuantity);
                            }} fullWidth />
                        </Tooltip >
                    </FormGroup>
                    <FormGroup>
                        <label className="productSelectFormLabel">Check Value</label>
                        <TextField id="endSerialNumber" disabled value={checkValue} className="" variant="outlined" fullWidth />
                    </FormGroup>

                </div>
                {SourcePKPNBox}
                {DestinationPKPNBox}
            </CardContent>

            <Backdrop className={classes.backdrop} open={openLoading} >
                <CircularProgress />
            </Backdrop>
        </Card>)

}
var ApplicationConstants = {};
var message;

export class Reallocate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardsData: [],
            AlertMessage: "",
            setNav: this.props.handleHistory,
            submitRequestData: {},
            openAlert: false,
            openConfirm: false,
            isSubmitDisabled: true,
            releasedProductLaunchId: this.props.projectID,
            UIDisplay: {
                "pkpnRangeCollection": [
                    {
                        "groupId": 0,
                        "rowId": " ",
                        "pkpn": " ",
                        "pkpnDescription": " ",
                        "lifetimeForecast": 0,
                        "uniqueNumberStart": 0,
                        "uniqueNumberEnd": 0,
                        "keyType": " ",
                        "launchQuantity": 0
                    }
                ],
                "rowCount": 1,
                "returnCode": 0,
                "returnMessage": null,
                "projectLaunchId": 0,
                "projectActivity": 0
            },
            DeleteReallocationProject: true,
            ProjectDeleted: false,
            loading:true
        };
        this.addMore = this.addMore.bind(this);
        this.clickSubmit = this.clickSubmit.bind(this);
        this.delete = this.delete.bind(this);
        this.updateState = this.updateState.bind(this);
        this.populateData = this.populateData.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.refresh = this.refresh.bind(this);

    }
    // var pkpnRangeCollection = this.state.UIDisplay.pkpnRangeCollection;

    updateState() {
        var enableSubmit = true;
        var countCards = 0;

        var checkDuplicate = [];
        var duplicates = [];
        for (var i = 0; i < this.state.cardsData.length; i++) {
            console.log(this.state.cardsData[i]);
            if (typeof this.state.cardsData[i] !== 'undefined') {
                countCards += 1;
                console.log(checkDuplicate);
                if (!this.state.cardsData[i].isValid) {
                    enableSubmit = false;
                    break;
                }

                if (checkDuplicate.indexOf(this.state.cardsData[i].cardSource.PKPN) !== -1) {
                    enableSubmit = false;
                    duplicates.push(this.state.cardsData[i].cardSource.PKPN);
                }

                if (checkDuplicate.indexOf(this.state.cardsData[i].cardDestination.PKPN) !== -1) {
                    enableSubmit = false;
                    duplicates.push(this.state.cardsData[i].cardSource.PKPN);
                }
                checkDuplicate.push(this.state.cardsData[i].cardSource.PKPN);
                if (this.state.cardsData[i].cardDestination.PKPN !== "")
                    checkDuplicate.push(this.state.cardsData[i].cardDestination.PKPN);
            }
        }

        console.log(enableSubmit);
        if (countCards !== 0 && enableSubmit === true)

            this.handleConfirm();
        else {
            if (duplicates.length !== 0)
                message = Constants.AlertMessages.Reallocation_MultipleError + duplicates.map(d => { return "\n" + d; })
            else
                message = Constants.AlertMessages.Reallocation_CannotSubmit;
            this.setState({ ...this.state, AlertMessage: message });
            console.log(message);
            console.log(this.state.AlertMessage);
            this.handleAlert();
        }
    }

    handleAlert() {
        var open = this.state.openAlert;
        this.setState({ ...this.state, openAlert: !open });
    }

    handleConfirm() {
        var open = this.state.openConfirm;
        this.setState({ ...this.state, openConfirm: !open });
    }
    componentWillMount() {
        ApplicationConstants = Constants.EndPoints[this.props.versionDetail];
        this.populateData(this.state.releasedProductLaunchId);
        this.addMore();
    }

    tabClosePopUp() {

        window.event.preventDefault();
        if (this.state.DeleteReallocationProject) {
            this.deleteProjectLaunch(this.state.releasedProductLaunchId);
            this.setState({ ProjectDeleted: true, DeleteReallocationProject: false })
        }

        return window.event.returnValue = '';
    }

    componentDidMount() {
        window.addEventListener("beforeunload",ev =>this.tabClosePopUp()); 
      
        if (document.getElementById('navBarAction').clicked == true) {
            if (this.state.DeleteReallocationProject) {
                this.deleteProjectLaunch(this.state.releasedProductLaunchId);
            }
        }
    }

    componentDidUpdate() {
        if (this.state.ProjectDeleted) {
            this.props.setNav(3);
        }
    }

    componentWillUnmount() {
        if (this.state.DeleteReallocationProject) {
            this.deleteProjectLaunch(this.state.releasedProductLaunchId);
            this.setState({ ProjectDeleted: true, DeleteReallocationProject: false })

        }
        window.removeEventListener("beforeunload", ev => this.tabClosePopUp());
    }

    async deleteProjectLaunch(releasedProductLaunchId) {
        const response = await fetch(ApplicationConstants.DeleteProject, {
            method: 'POST',
            body: releasedProductLaunchId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const deleteData = await response.json();
        return deleteData;
    }

    async populateData(releasedProductLaunchId) {
        const response = await fetch(ApplicationConstants.Reallocate, {
            method: 'POST',
            body: releasedProductLaunchId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        console.log(response)
        const data = await response.json();
        console.log(data);
        if (data.rowCount > 0) {
            this.setState({ UIDisplay: data, releasedProductLaunchId: data.projectLaunchId, loading: false});
        } else {
            //error pop up
        }
        if (data.returnCode !== 0) {
            this.setState({ DeleteReallocationProject: false })
        }
        this.setState({ loading: false })
    }


    addMore() {
        console.log(this.state.UIDisplay);
        this.state.cardsData.push(new CardObject());

        var temp = this.state.cardsData;
        this.setState({ cardsData: temp });
        console.log(this.state.cardsData);
    }

    delete(ind) {
        console.log(ind)
        var temp = this.state.cardsData;
        temp.splice(ind, 1, undefined);

        this.setState({ cardsData: temp });
        console.log(this.state.cardsData);
    }

    refresh(ind) {
        console.log(ind)
        var temp = this.state.cardsData;
        temp.splice(ind, 1, undefined, new CardObject());


        this.setState({ cardsData: temp });
        console.log(this.state.cardsData);
    }


    async clickSubmit() {
        var submitRequestData = {};
        var data = this.state.cardsData;
        this.setState({ loading:true })
        submitRequestData["ProjectLaunchID"] = this.state.releasedProductLaunchId;
        var ReallocationData = {};
        for (var i = 0; i < this.state.cardsData.length; i++) {
            console.log(data[i])
            if (typeof data[i] !== undefined) {
                ReallocationData[data[i].cardSource.PKPN] = [];
                ReallocationData[data[i].cardSource.PKPN].push(data[i].cardSource);
                ReallocationData[data[i].cardSource.PKPN].push(data[i].cardDestination);
            }
        }
        submitRequestData["ReallocationData"] = ReallocationData;

        console.log(submitRequestData);
        var submitResponse = await submitRequestDataAsync(submitRequestData, this.props.accountInfo);
        if (submitResponse.returnCode === 0) {
            this.setState({ DeleteReallocationProject: false })
        }
        //this.handleHistory();
        this.setState({ loading: false })
        console.log(submitResponse);
        this.props.setNav(3);
    }

    render() {

        return (
            <div>
                <header role="heading" className="Header" aria-level="1">
                    <h1>Reallocate </h1>
                    <h1 id="SubHeading">{this.props.productName}</h1>
                </header>
                <div id="MainHolder">
                    <div>
                        <div id="Cards">
                            {this.state.cardsData.map((data, index) => data === undefined ? null : < ReallocateCard data={data} index={index} delete={this.delete} refresh={this.refresh} UIDisplay={this.state.UIDisplay} loading={ this.state.loading}/>)}
                        </div>
                        <Fab id="PPPButtonAdd" onClick={this.addMore} aria-label="add" variant="extended">
                            <AddIcon />Add
                        </Fab>
                    </div>
                    <div id="Actions">


                        <Button id="PPPButton" variant="contained" onClick={this.updateState}>Submit</Button>
                        <Button id="PPPButton" variant="contained" onClick={e => {
                            this.deleteProjectLaunch(this.state.releasedProductLaunchId); this.setState({ ProjectDeleted: true, DeleteReallocationProject: false }); this.props.setNav(3); }}>Cancel</Button>
                    </div>
                </div>

                <div >
                    <Dialog onClose={this.handleAlert} aria-labelledby="customized-dialog-title" open={this.state.openAlert} >
                        <DialogTitle id="customized-dialog-title" >
                            <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> Alert
                        </DialogTitle>
                        <DialogContent dividers>
                            {message}
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={this.handleAlert} color="primary">
                                Close
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div >
                    <Dialog onClose={this.handleConfirm} aria-labelledby="customized-dialog-title" open={this.state.openConfirm} >
                        <DialogTitle id="customized-dialog-title" >
                            <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> Confirm
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography>Do you want to proceed with reallocation ?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => {
                                this.handleConfirm();
                                this.clickSubmit();
                            }} color="primary">
                                Yes
                        </Button>
                            <Button onClick={this.handleConfirm} color="primary">
                                No
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div >
        )
    }
}


async function submitRequestDataAsync(data, accountInfo) {

    const response = await fetch(ApplicationConstants.SubmitReallocation, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accountInfo.idToken}`,
            'Authorization-AccessToken': `Bearer ${accountInfo.accessToken}`
        }
    });

    const projectLaunchData = await response.json();
    return projectLaunchData;
}
class ReallocationItem {

    constructor() {

        this.PKPN = "";
        this.GroupId = null;
        this.IsNewPKPN = true;
        this.LaunchQuantity = 0;
        this.LifetimeForecast = 0;
        this.UniqueNumberEnd = 0;
        this.UniqueNumberStart = 0;
        this.RowID = "";
        this.Descriptor = "";
        this.ProgramPurpose = "";

    }
}

class CardObject {
    constructor() {
        this.cardSource = new ReallocationItem();
        this.cardDestination = new ReallocationItem();
        this.isValid = false;
    }
}
