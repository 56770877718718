import React, { useEffect } from 'react';
import './Home/Home.css'
import NavBar from './NavBar';
import Constants from '../ApplicationConstants.js';

export default function PK2009(props) {

    return (
        <section className="MainContainer">
            <NavBar versionDetail={"SubClass6"} {...props} />
        </section>
    );
}