import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import mslogo from '../../Images/mslogo.png';
import './Footer.css';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <footer id="PageFooter" className="pagePadding">
                    <span className="footer-links">
                        <span className="footer-link">Microsoft Confidential</span>
                    <span className="footer-link"><a href={"mailto:" + this.props.email} > Contact Us</a></span>
                    </span>
                <span className="mslogo"><img src={mslogo} alt="MsLogo" align="right" /></span>
            </footer>
        );
    }
}