import { Backdrop, Button, CircularProgress, FormControl, FormGroup, Radio, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../ApplicationConstants';
import './Range.css';

const rangeDetailColumns = [
    { id: 'pkpn', label: 'Part Number', minWidth: 170 },
    {
        id: 'uniqueNumberStart',
        label: 'Start Serial Number',
        minWidth: 190,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'uniqueNumberEnd',
        label: 'End Serial Number',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    { id: 'pkpnDescription', label: 'Description', minWidth: 170 },
    { id: 'groupIDTypeEnum', label: 'Group ID Type', minWidth: 180 },
];

var ApplicatonSettings = {};

const rangesColumn = [
    {
        id: 'startSerialNumber',
        label: 'Start Serial Number',
        minWidth: 190,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'endSerialNumber',
        label: 'End Serial Number',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
];

const useStyles = makeStyles(theme => ({
    pkpnTable: {

        justifyContent: 'center',
        margin: '2em',
    },
    container: {
        maxHeight: 250,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const pkpnColumn = [
    { id: 'pkpn', label: 'Part Number', minWidth: 100 },
    {
        id: 'groupId',
        label: 'Group Id',
        minWidth: 100,
        format: value => value.toLocaleString(),
    },
    { id: 'pkpnDescription', label: 'Description', minWidth: 100 },
];

const availableranges = [1, 2, 3, 4, 5];


const DialogTableColumns =
    [
        {
            id: 'PKPN',
            label: 'Part Number',
            minWidth: 100
        },
        {
            id: 'startSerialNumber',
            label: 'Start Serial Number',
            minWidth: 100,
            align: 'right',
            format: value => value.toLocaleString(),
        },
        {
            id: 'endSerialNumber',
            label: 'End Serial Number',
            minWidth: 100,
            align: 'right',
            format: value => value.toLocaleString(),
        },
    ];

const SelectedRangeColumns =
    [
        {
            id: 'startSerialNumber',
            label: 'Start Serial Number',
            minWidth: 190,
            align: 'right',
            format: value => value.toLocaleString(),
        },
        {
            id: 'endSerialNumber',
            label: 'End Serial Number',
            minWidth: 170,
            align: 'right',
            format: value => value.toLocaleString(),
        },
    ];

async function isRangeOverlapping(data, idToken, accessToken) {
    const response = await fetch(ApplicatonSettings.IsOverlap, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const isOverlapping = await response.json();
    return isOverlapping;
}

async function baselineProject(data, idToken, accessToken) {
    const response = await fetch('/baselinePid3ProjectLaunch', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const baselineProjectData = await response.json();
    return baselineProjectData;
}

async function releaseProject(data, idToken, accessToken) {
    const response = await fetch('/releaseProjectLaunch', {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const releaseData = await response.json();
    return releaseData;
}

var dataSet = [];

function Baseline(props) {
    const classes = useStyles();
    
    const [selectedPkpn, setSelectedPKPN] = React.useState('');
    const [selectedGroupId, setSelectedGroupId] = React.useState(-1);
    const [selected, setSelected] = React.useState([]);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [partNumber, setPartNumber] = React.useState('');
    const [selectedStartRange, setSelectedStartRange] = React.useState(0);
    const [selectedEndRange, setSelectedEndRange] = React.useState(0);
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertBoxMessage, setAlertBoxMessage] = React.useState('');
    const [successfullAction, setSuccessfullAction] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openOverlappingAlert, setOpenOverlappingAlert] = React.useState(false);
    const [modifySelectedRange, setModifySelectedRange] = React.useState(false);
    const [modifyingRange, setModifyingRange] = React.useState({});
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [openLoading, setOpenLoading] = React.useState(false);
    const [selectedRanges, setSelectedRanges] = React.useState([]);
    const [barClicked, setBARClicked] = React.useState(false);

    const handleSelectPkpnChange = (row) => {
        var payload = { 'Ranges': selectedRanges, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId, 'PKPN': selectedPkpn };
        pushToDataSet(payload);
        setSelectedPKPN(row.pkpn);
        var ranges = checkSelectedRangeExists(row.pkpn);
        setSelectedRanges(ranges);
        setSelectedGroupId(row.groupId);
        setSelectedValue(row.pkpn);
        setSelectedStartRange(0);
        setSelectedEndRange(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = () => {
        var index = -1;
        if (selectedRanges != null) {
            selectedRanges.forEach(function (range, i) {
                if (parseInt(range.startSerialNumber) === parseInt(modifyingRange.startSerialNumber) && parseInt(range.endSerialNumber) === parseInt(modifyingRange.endSerialNumber)) {
                    index = i;
                }
            }
            )
            if (index !== -1) {
                selectedRanges.splice(index, 1);
            }
        }
        setModifySelectedRange(false);
        setSelectedStartRange(0);
        setSelectedEndRange(0);
        setModifyingRange({});
    }

    const checkRangeUsed = () => {
        var flag = false;
        console.log('CheckRangeUsed');
        if (selectedRanges != null) {

            selectedRanges.forEach(function (range) {

                if (((parseInt(selectedStartRange) >= parseInt(range.startSerialNumber) && parseInt(selectedStartRange) <= parseInt(range.endSerialNumber))
                    || (parseInt(selectedEndRange) >= parseInt(range.startSerialNumber) && parseInt(selectedEndRange) <= parseInt(range.endSerialNumber)))
                    && (parseInt(modifyingRange.startSerialNumber) !== parseInt(range.startSerialNumber) && parseInt(modifyingRange.endSerialNumber) !== parseInt(range.endSerialNumber))) {
                    flag = true;
                }
            })
        }
        return flag;
    }

    const checkSelectedRangeExists = (PKPN) => {
        var range = null;
        dataSet.forEach(function (data) {
            if (data.PKPN === PKPN) {
                console.log('Ranges');
                console.log(data.Ranges);
                range = data.Ranges;
            }
        })
        return range;
    }

    const checkSelectedRangeExistsWithGroupId = () => {
        var rangeOverlap = false;
        dataSet.forEach(function (data) {
            console.log(dataSet)
            console.log(selectedGroupId)
            if (((parseInt(selectedGroupId) % 2) == 0) ? (parseInt(selectedGroupId) === parseInt(data.GroupId) || parseInt(selectedGroupId) === (parseInt(data.GroupId) - 1)) : (parseInt(selectedGroupId) === parseInt(data.GroupId) || parseInt(selectedGroupId) === (parseInt(data.GroupId) + 1))) {
                console.log("Enter");
                data.Ranges.forEach(function (ranges) {
                    console.log(ranges)
                    if ((parseInt(ranges.startSerialNumber) <= parseInt(selectedStartRange)) && (parseInt(ranges.endSerialNumber) >= parseInt(selectedStartRange))
                        || (parseInt(ranges.startSerialNumber) <= parseInt(selectedEndRange)) && (parseInt(ranges.endSerialNumber) >= parseInt(selectedEndRange))) {
                        rangeOverlap = true;
                    }
                })
            }
        })
        return rangeOverlap;
    }

    const handleAddClick = async () => {
        var usedRange = checkRangeUsed();
        var groupRangeCheck = checkSelectedRangeExistsWithGroupId();
        setOpenAlert(false);
        if (parseInt(selectedStartRange) >= parseInt(selectedEndRange)) {
            setAlertTitle('Range Selection');
            setAlertBoxMessage('End serial number cannot be less than the start serial number');
            setOpenAlert(true);
        } else if (usedRange) {
            setAlertTitle('Range Selection');
            setAlertBoxMessage('The selected range is already used.');
            setOpenAlert(true);
        } else if (groupRangeCheck) {
            setAlertTitle('Range Selection');
            setAlertBoxMessage('The selected range is already used.');
            setOpenAlert(true);
        }
        else {
            var payload = { 'StartSerialNumber': selectedStartRange, 'EndSerialNumber': selectedEndRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId }
            var isOverlapping = await isRangeOverlapping(payload, props.accountInfo.idToken, props.accountInfo.accessToken);
            if (isOverlapping) {
                setAlertTitle('Overlapping');
                setAlertBoxMessage('The selected range will result in an overlapping scenario. Do you wish to proceed ?');
                setOpenOverlappingAlert(true);
            } else {
                console.log('Hello');
                var currentRange;
                selectedRanges == null ? currentRange = [{ 'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange, 'IsOverLapRange': 0 }]
                    : currentRange = [...selectedRanges, { 'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange, 'IsOverLapRange': 0 }];
                setSelectedRanges(currentRange);
                adjustAvailableRanges();
                setOpenAlert(false);
                var payload = { 'Ranges': currentRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId, 'PKPN': selectedPkpn };
                pushToDataSet(payload);
            }
        }
    }

    const adjustAvailableRanges = () => {
      

        var GroupID = selectedGroupId;

            var rangeValue = {
                'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange}
                console.log('update available range')
                console.log(rangeValue
                )
                props.availableRangesCopy.forEach(function (availableRange, availableRangeIndex) {

                    console.log(availableRange)
                    console.log(GroupID)
                    if (parseInt(availableRange.GroupId) == parseInt(GroupID)) {
                        if ((parseInt(availableRange.StartSerialNumber) == parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) == parseInt(rangeValue.endSerialNumber))) {
                            props.availableRanges.splice(availableRangeIndex, 1);
                        }
                        else if ((parseInt(availableRange.StartSerialNumber) == parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) > parseInt(rangeValue.endSerialNumber))) {
                            props.availableRanges[availableRangeIndex].StartSerialNumber = rangeValue.endSerialNumber + 1;
                        }
                        else if ((parseInt(availableRange.StartSerialNumber) < parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) == parseInt(rangeValue.endSerialNumber))) {
                            props.availableRanges[availableRangeIndex].EndSerialNumber = rangeValue.startSerialNumber - 1;
                        }
                        else if ((parseInt(availableRange.StartSerialNumber) < parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) > parseInt(rangeValue.endSerialNumber))) {
                            props.availableRange.EndSerialNumber = rangeValue.startSerialNumber - 1;
                            props.availableRanges.splice(availableRangeIndex, 0, { 'StartSerialNumber': rangeValue.endSerialNumber + 1, 'EndSerialNumber': availableRange, 'GroupId': GroupID });
                        }
                        else if ((parseInt(availableRange.StartSerialNumber) < parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) < parseInt(rangeValue.endSerialNumber))) {
                            props.availableRange.EndSerialNumber = rangeValue.startSerialNumber - 1;
                        }
                        else if ((parseInt(availableRange.StartSerialNumber) < parseInt(rangeValue.startSerialNumber)) && (parseInt(availableRange.EndSerialNumber) > parseInt(rangeValue.endSerialNumber))) {
                            props.availableRange.StartSerialNumber = rangeValue.endSerialNumber + 1;
                        }
                    }
                })
        
        
    }

    const handleUpdateClick = async () => {
        var usedRange = checkRangeUsed();
        if (selectedStartRange >= selectedEndRange) {
            setAlertTitle(Constants.AlertTitles.RangeSelection);
            setAlertBoxMessage(Constants.AlertMessages.Range_SerialNumberFault);
            setOpenAlert(true);
        } else if (usedRange) {
            setAlertTitle('Range Selection');
            setAlertBoxMessage('Range already used');
            setOpenAlert(true);
        } else {
            var payload = { 'StartSerialNumber': selectedStartRange, 'EndSerialNumber': selectedEndRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId }
            var isOverlapping = await isRangeOverlapping(payload, props.accountInfo.idToken, props.accountInfo.accessToken);
            if (isOverlapping) {
                setAlertTitle(Constants.AlertTitles.Overlapping);
                setAlertBoxMessage(Constants.AlertMessages.Range_Overlapping);
                payload = { 'StartSerialNumber': selectedStartRange, 'EndSerialNumber': selectedEndRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId, 'IsOverLapRange': isOverlapping }
                setOpenOverlappingAlert(true);
            } else {
                editSelectedRange(0);
                adjustAvailableRanges();
                setOpenAlert(false);
            }
        }
        setModifySelectedRange(false);
        setSelectedStartRange(0);
        setSelectedEndRange(0);
        setModifyingRange({});
    }

    const handleBaselineAndReleaseClick = async () => {
        setSnackBarMessage('Baseline Started');
        setOpenSnackBar(true);
        setOpenLoading(true);
        var baselineResult = await baselineProject(dataSet, props.accountInfo.idToken, props.accountInfo.accessToken);
        if (baselineResult.returnCode === 0) {
            setSnackBarMessage('Release Started');
            var releaseResult = await releaseProject(props.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            if (releaseResult.returnCode === 0) {
                setOpenSnackBar(false);
                setOpenLoading(false);
                setSuccessfullAction(true);
                setAlertTitle('Baseline & Release');
                setAlertBoxMessage('The project was successfully released');
                setOpenAlert(true);
            }
            else {
                setOpenSnackBar(false);
                setOpenLoading(false);
                setAlertTitle('Baseline & Release');
                setAlertBoxMessage(releaseResult.returnMessage);
                setOpenAlert(true);
            }
        }
        else {
            setOpenSnackBar(false);
            setOpenLoading(false);
            setAlertTitle('Baseline & Release');
            setAlertBoxMessage(baselineResult.returnMessage);
            setOpenAlert(true);
        }
        setBARClicked(true);
    }

    const editSelectedRange = (isOverlapping) => {
        var currentRange = [];
        if (selectedRanges != null) {
            selectedRanges.map(function (range) {
                if (parseInt(range.startSerialNumber) == parseInt(modifyingRange.startSerialNumber) && parseInt(range.endSerialNumber) == parseInt(modifyingRange.endSerialNumber)) {
                    range.startSerialNumber = selectedStartRange;
                    range.endSerialNumber = selectedEndRange;
                    range.isOverlapping = isOverlapping;
                    currentRange.push({ 'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange, 'IsOverLapRange': isOverlapping });
                }
                else {
                    currentRange.push(range);
                }
            })

            var payload = { 'Ranges': currentRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId, 'PKPN': selectedPkpn };
            pushToDataSet(payload);
        }
    }

    const handleAlertClose = () => {
        if (barClicked) {
            props.setNav(3)
        }
        setOpenAlert(false);
    };

    const pushToDataSet = (payload) => {
        if (parseInt(payload.GroupId) != -1 && payload.Ranges != null) {
            var flag = 0;
            dataSet.forEach(function (data) {
                if (data.PKPN === payload.PKPN) {
                    data.Ranges = payload.Ranges
                    flag = 1;
                }
            })
            if (flag == 0) {
                dataSet.push(payload);
            }
        }
    }

    const handleRangeSelection = (row) => {
        console.log('data');
        console.log(row);

        setSelectedStartRange(row.startSerialNumber);
        setSelectedEndRange(row.endSerialNumber);
    }
    const handleOverlappingAlertClose = () => {
        setOpenOverlappingAlert(false);
    };

    const handleOverlappingConfirm = () => {
        if (modifySelectedRange) {
            editSelectedRange(1);
        } else {
            var currentRange;
            selectedRanges == null ? currentRange = [{ 'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange, 'IsOverLapRange': 1 }]
                : currentRange = [...selectedRanges, { 'startSerialNumber': selectedStartRange, 'endSerialNumber': selectedEndRange, 'IsOverLapRange': 1 }];
            setSelectedRanges(currentRange);
            var payload = { 'Ranges': currentRange, 'GroupId': selectedGroupId, 'ProjectLaunchId': props.projectLaunchId, 'PKPN': selectedPkpn };
            pushToDataSet(payload);
        }
        adjustAvailableRanges();
        setOpenOverlappingAlert(false);
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleBaselineClick = async () => {
        var baseline = await baselineProject(dataSet, props.accountInfo.idToken, props.accountInfo.accessToken);
    }

    const handleSelectedRangeClick = (row) => {
        setModifySelectedRange(!modifySelectedRange);
        setSelectedStartRange(row.startSerialNumber);
        setSelectedEndRange(row.endSerialNumber);
        setModifyingRange({ 'startSerialNumber': row.startSerialNumber, 'endSerialNumber': row.endSerialNumber })
    }

    const handleCancelClick = () => {
        setModifySelectedRange(false);
        setSelectedStartRange(0);
        setSelectedEndRange(0);
        setModifyingRange({});
    }

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const OverlappingAlert = (<Dialog
        open={openOverlappingAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullAction ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{alertTitle}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertBoxMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleOverlappingAlertClose} color="primary" autoFocus>
                Cancel
                    </Button>
            <Button onClick={event => handleOverlappingConfirm()} color="primary" autoFocus>
                Confirm
                    </Button>
        </DialogActions>
    </Dialog>)

    const Alerts = (<Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullAction ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{alertTitle}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertBoxMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleAlertClose} color="primary" autoFocus>
                Close
                    </Button>
        </DialogActions>
    </Dialog>)

    const GroupIdTable = (
        <TableContainer className={classes.container} >
            <Table stickyHeader id="pkpnTable" aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell id="TableHeader" className="headerBorder"
                            key="radioButton"
                            align="left"
                            style={{ width: 15 }}
                        />
                        {pkpnColumn.map(column => (
                            <TableCell id="TableHeader" className="headerBorder"
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.newPkpns && props.newPkpns.length > 0 ?
                        props.newPkpns.map(row => {
                            const isItemSelected = isSelected(row.name);
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={event => handleSelectPkpnChange(row)} selected={row.pkpn === selectedPkpn} value={row.pkpn} selected={isItemSelected}>
                                    <TableCell id="PKPNTableBody" key="radioButton">
                                        <Radio
                                            checked={selectedPkpn === row.pkpn}
                                            name="radio-button-demo"
                                            onChange={event => handleSelectPkpnChange(row)}
                                        />
                                    </TableCell>
                                    {pkpnColumn.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell id="PKPNTableBody" key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }) :
                        null
                    }
                </TableBody>
            </Table>
        </TableContainer>)

    const ReallocationAutocompleteBox = (<div id="SelectPKPN">
        <Autocomplete
            id="productName"
            options={props.reallocationPkpns}
            getOptionLabel={option => option.pkpn}
            onSelect={(event) => setPartNumber(event.target.value)}
            renderInput={params => (
                <TextField {...params} label="Select Part Number" variant="outlined" fullWidth />
            )}
        />
    </div>)

    const AvailableRanges = (<div className="availableRangesBox">
        <div>
            <h3>Available Ranges</h3>
        </div>
        <div className="productName">
            <TableContainer className={classes.container}>
                <Table stickyHeader id="groupIDTable" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                       
                            {rangesColumn.map(column => (
                                <TableCell id="TableAvaialbleHeader" className="headerBorder"
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.availableRanges && props.availableRanges.length > 0 ?
                            props.availableRanges.map(row => {
                                const isItemSelected = isSelected(row.name);
                                if (selectedGroupId != -1 && selectedGroupId % 2 === 0 && (row.groupId === selectedGroupId || row.groupId === selectedGroupId + 1)) {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={event => handleRangeSelection(row)} selected={row.pkpn === selectedPkpn} value={row.pkpn} selected={isItemSelected}>
                                           
                                          
                                            {rangesColumn.map(column => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }
                                else if (selectedGroupId != -1 && !selectedGroupId % 2 === 0 && (row.groupId === selectedGroupId || row.groupId === selectedGroupId - 1)) {
                                    return (
                                        <TableRow >
                                            {rangesColumn.map(column => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }
                                else {
                                    return (<TableRow />);
                                }
                            }) :
                            null}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
    )

    const GroupIdRangesTable = (<div className="rangeBox">
        <div className="releaseRangesBox">
            <div>
                <h3>Group ID Ranges</h3>
                <div>
                    <div id="productName">
                        <TableContainer className={classes.container}>
                            <Table stickyHeader id="groupIDTable" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {rangeDetailColumns.map(column => (
                                            <TableCell id="TableAvaialbleHeader" className="headerBorder"
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.existingRangeDetails && props.existingRangeDetails.length > 0 ?
                                        props.existingRangeDetails.map(row => {
                                            const isItemSelected = isSelected(row.name);
                                            if (row.groupIDTypeEnum == 0) {
                                                row.groupIDTypeEnum = "Retail";
                                            }
                                            else {
                                                row.groupIDTypeEnum = "OEM";
                                            }
                                            if (selectedGroupId != -1 && selectedGroupId % 2 === 0 && (row.groupID === selectedGroupId || row.groupID === selectedGroupId + 1) && row.pkpn != null) {
                                                return (
                                                    <TableRow >
                                                        {rangeDetailColumns.map(column => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            }
                                            else if (selectedGroupId != -1 && selectedGroupId % 2 != 0 && (row.groupID === selectedGroupId || row.groupID === selectedGroupId - 1) && row.pkpn != null) {
                                                console.log('condition2');
                                                return (
                                                    <TableRow >
                                                        {rangeDetailColumns.map(column => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            }
                                            else {
                                                return (<TableRow />);
                                            }
                                        }) :
                                        null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
        {AvailableRanges}
    </div>
    )

    const selectedRangeTable = (<div>
        <div>
            <div id="productName">
                <TableContainer className={classes.container} >
                    <Table stickyHeader id="selectedRangesTable" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {SelectedRangeColumns.map(column => (
                                    <TableCell id="TableAvaialbleHeader" className="headerBorder"
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedRanges != null ? selectedRanges.map(row => {
                                return (
                                    <TableRow hover onClick={event => handleSelectedRangeClick(row)} selected={row.startSerialNumber === modifyingRange.startSerialNumber}>
                                        {SelectedRangeColumns.map(column => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )
                            })
                                : <TableRow />}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    </div>
    )

    const baselineConfirmationDialog = (
        <Dialog onClose={handleClose} id="DialogBox" aria-labelledby="baseline" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} />
                Baseline
                 </DialogTitle>
            <DialogContent dividers>
                <TableContainer >
                    <Table stickyHeader id="groupIDTable" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {DialogTableColumns.map(column => (
                                    <TableCell id="TableAvaialbleHeader" className="headerBorder"
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSet != null ? dataSet.map(row => {
                                return (
                                    <TableRow hover>
                                        {DialogTableColumns.map(column => {
                                            if (column.id === "PKPN") {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            }
                                            else {
                                                {
                                                    row.Ranges.map(range => {
                                                        const data = range[column.id];
                                                        return (
                                                            <TableCell id="GroupIDTableBody" key={column.id} align={column.align}>
                                                                {data}
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                            }
                                        })}
                                    </TableRow>
                                )
                            })
                                : <TableRow />}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Confirm
                    </Button>
            </DialogActions>
        </Dialog>)

    return (
        <section>
            <header role="heading" className="Header">
                <h1>{props.reallocation ? "Reallocation" : "Baseline"}</h1>
            </header>
            {GroupIdRangesTable}
            <div >
                <h3 marginTop='10px' > Select PKPN Range</h3>
                {props.reallocation ? ReallocationAutocompleteBox : GroupIdTable}
                {Alerts}
                {OverlappingAlert}
            </div>
            {baselineConfirmationDialog}
            <div className='secondrangeBox'>
                <FormControl className="selectRangeBox">
                    <FormGroup>
                        <label for="startSerialNumber" className="productSelectFormLabel">Start Serial Number (*)</label>
                        <TextField id="startSerialNumber" type="number" label="Select Start Range" className="" variant="outlined" disabled={selectedPkpn === ""} value={selectedStartRange} onChange={event => setSelectedStartRange(event.target.value)} fullWidth />
                        <label for="endSerialNumber" className="productSelectFormLabel">End Serial Number (*)</label>
                        <TextField id="endSerialNumber" type="number" label="Select End Range" className="" disabled={selectedPkpn === ""} variant="outlined" value={selectedEndRange} onChange={event => setSelectedEndRange(event.target.value)} fullWidth />
                        <label for="endSerialNumber" className="productSelectFormLabel">Calculated Range</label>
                        <TextField  type="number" className="" disabled={true} variant="outlined" value={selectedEndRange - selectedStartRange +1} />
                    </FormGroup>
                    <div id="NavigationContainer">
                        {modifySelectedRange === false ? <Button size="large" id="PPPButton" color="primary" variant="contained" disabled={selectedPkpn === ""} onClick={event => handleAddClick()} startIcon={<UpdateIcon />}>Add</Button>
                            : <React.Fragment>
                                <Button size="large" color="primary" id="PPPButton" variant="contained" disabled={selectedPkpn === ""} onClick={event => handleUpdateClick()} startIcon={<UpdateIcon />}>Update</Button>
                                <Button size="large" color="primary" id="PPPButton" variant="contained" disabled={selectedPkpn === ""} onClick={event => handleCancelClick()} startIcon={<CancelIcon />}>Cancel</Button>
                            </React.Fragment>
                        }
                    </div>
                </FormControl>
                <FormControl id="SelectedRangeBox">
                    <FormGroup>
                        <label for="selectedRange" className="productSelectFormLabel">Selected Range</label>
                        {selectedRangeTable}
                    </FormGroup>
                    <div id="NavigationContainer">
                        <Button size="large" color="primary" id="PPPButton" variant="contained" disbaled={modifySelectedRange == false} onClick={event => handleDeleteClick()} startIcon={<DeleteIcon />}>Delete</Button>
                    </div>
                </FormControl>
            </div>
            <div id="MainNavigationContainer">
                <Button size="large" variant="contained" color="primary" id="PPPButton" onClick={event => handleBaselineAndReleaseClick()} >Baseline & Release</Button>
            </div>
            <Backdrop className={classes.backdrop} open={openLoading} >
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
        </section>
    );
}

Baseline.propTypes = {
    newPkpns: PropTypes.array,
    reallocationPkpns: PropTypes.array,
    setNav: PropTypes.func,
    reallocation: PropTypes.bool,
    existingRangeDetails: PropTypes.array,
    availableRanges: PropTypes.array,
    availableRangesCopy: PropTypes.array,
    projectLaunchId: PropTypes.number,
    accountInfo: PropTypes.object
};

export class Range extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newPkpns: [],
            reallocationPkpns: [],
            existingRangeDetails: [],
            availableRanges: [],
            availableRangesCopy: [],
            setNav: this.props.setNav,
            reallocation: this.props.reallocation,
            projectLaunchId: this.props.projectID,
            accountInfo: props.accountInfo
        };
    }

    async getPKPNDataForBaseline() {
        console.log(this.props.projectID);
        const response = await fetch(ApplicatonSettings.Getnewpkpnforbaseline, {
            method: 'POST',
            body: (this.props.projectID),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ newPkpns: data })
    }

    async getPKPNDataForReallocationPID3() {
        const response = await fetch(ApplicatonSettings.Getallpkpndata, {
            method: 'POST',
            body: (this.props.projectID),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ reallocationPkpns: data });
    }

    async getExistingRangeDetails() {
        const response = await fetch(ApplicatonSettings.GetPrePKRangeDetails, {
            method: 'POST',
            body: (this.props.projectID),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ existingRangeDetails: data });
    }

    async getAvailableRanges() {
        const response = await fetch(ApplicatonSettings.GetAvailableRanges, {
            method: 'POST',
            body: (this.props.projectID),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        });
        const data = await response.json();
        this.setState({ availableRanges: data, availableRangesCopy: data });
    }

    componentWillMount() {
        ApplicatonSettings = Constants.EndPoints[this.props.versionDetail]
    }

    componentDidMount() {
        this.getExistingRangeDetails();
        this.getAvailableRanges();
        dataSet = []
        if (!this.props.reallocation) {
            this.getPKPNDataForBaseline();
        } else {
            this.getPKPNDataForReallocationPID3();
        }
    }

    render() {
        return (
            <div>
                <Baseline {...this.state}/>
            </div>
        );
    }
}