import React, { Component } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LaunchIcon from '@material-ui/icons/Launch'
import HistoryIcon from '@material-ui/icons/History';
import PartNumber from './PartNumber/PartNumber';
import { Range } from './Range/Range';
import  NoAccess from './NoAccess/NoAccess';
import { NewProject } from './NewProject/NewProject';
import UploadPKSpec from './UploadPKSpec/UploadPKSpec';
//import Reallocate from './Reallocate/Reallocate';
import { History } from './History/CardData'
import './NavBar.css'
import { AdminSettings } from './AdminSettings/AdminSetting';
import { Reallocate } from './Reallocate/Reallocate';
import { ReallocatePrePK } from './ReallocatePrePK/ReallocatePrePK';
//import NoAccess from './UploadPKSpec/UploadPKSpec';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: '1 0 auto',
        position: 'relative',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        
        zIndex: 'auto',
        backgroundColor: '#2c3e50',
        paddingTop: '6em',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
       
        zIndex: 'auto',
        backgroundColor: '#2c3e50',
        paddingTop: '6em',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: '2.5em',
    },
    icons: {
        color:'white',
    }
}));

export default function NavBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [nav, setNav] = React.useState(0);
    const [projectID, setProjectID] = React.useState(0);
    const [reallocation, setReallocation] = React.useState(false);
    const [productName, setProductName] = React.useState("");
    const versionDetail = props.versionDetail;
    const handleNewProjectLaunch = () => {
        setNav(1);
    };

    const handleAdminSettings = () => {
        setNav(2);
    };

    const handleHistory = () => {
        setNav(3);
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
        console.log(versionDetail);
    };

    const handleRangeNext = () => {
        setNav(5);
    }

    const handleUploadButtonClick = () => {
        setNav(4);
    }

    const handleBaselineButtonClick = () => {
        setNav(6);
    }
    const access = versionDetail === 'PK 2009';
    return (
        < div className={classes.root} >
            <Drawer
                variant='permanent'
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton className={classes.icons} onClick={handleDrawerToggle} aria-label={(theme.direction === 'ltr' && !open) ? "Expand" : "Contract"}>
                        {(theme.direction === 'ltr' && !open) ? <ChevronRightIcon id="IconContrastRight" /> : <ChevronLeftIcon id="IconContrastLeft"/>}
                    </IconButton>
                </div>
                <Divider />
                <List id="navBarAction">
                    <li>
                    <ListItem button onClick={event => handleNewProjectLaunch()} className={classes.icons} id="navBarAction2">
                        <div>
                                <ListItemIcon className={classes.icons}><LaunchIcon id="IconContrastLaunch" fontSize='medium' /></ListItemIcon>
                        </div>
                        <div>
                            <ListItemText primary="New Project" />
                        </div>
                    </ListItem>
                    </li>
                </List>
                <List>
                    <li>
                    <ListItem button onClick={event => handleAdminSettings()} className={classes.icons} id="navBarAction3">
                        <div>
                                <ListItemIcon className={classes.icons}><SupervisorAccountIcon id="IconContrastUser" fontSize='medium'  /></ListItemIcon>
                             </div>
                        <div>
                            <ListItemText primary="Administration Settings" />
                        </div>
                    </ListItem>
                    </li>
                </List>
                <Divider />
                <List id="navBarAction4">
                    <li>
                    <ListItem button onClick={event => handleHistory()} className={classes.icons} id="navBarAction5">
                            <div> <ListItemIcon className={classes.icons}><HistoryIcon id="IconContrastHistory" fontSize='medium' /></ListItemIcon>
                        </div>
                        <div>
                            <ListItemText primary="History" />
                        </div>
                        </ListItem>
                        </li>
                </List>
            </Drawer>
            <main className={classes.content}>
                {props.idToken != null ?
                    <Typography paragraph id="contentProp">
                        {nav === 0 ? <History setNav={setNav} setProductName={setProductName} setProjectID={setProjectID} versionDetail={versionDetail} setReallocation={setReallocation} accountInfo={props} /> : null}
                        {nav === 1 ? <NewProject setNav={setNav} accountInfo={props} versionDetail={versionDetail} /> : null}
                        {nav === 2 ? <AdminSettings accountInfo={props} versionDetail={versionDetail}/> : null}
                        {nav === 3 ? <History setNav={setNav} versionDetail={versionDetail} setProductName={setProductName} setProjectID={setProjectID} setReallocation={setReallocation} accountInfo={props} /> : null}
                        {nav === 4 ? <UploadPKSpec setNav={setNav} projectID={projectID} /> : null}
                        {nav === 5 ? <Reallocate setNav={setNav} productName={productName} versionDetail={versionDetail} handleHistory={handleHistory} projectID={projectID} accountInfo={props} /> : null}
                        {nav === 6 ? <Range setNav={setNav} projectID={projectID} reallocation={reallocation} accountInfo={props} versionDetail={versionDetail} /> : null}
                        {nav === 7 ? <ReallocatePrePK setNav={setNav} productName={productName} versionDetail={versionDetail} handleHistory={handleHistory} projectID={projectID} accountInfo={props} /> : null}
                    </Typography> : null}
            </main>
        </div> 
);
}