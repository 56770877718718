import React, { Component } from 'react';
import '../NewProject/NewProject';
import './UnauthorizedAccess.css';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

export default function UnauthorizedAccess(props) {
    return (
        <section id="uploadPKSpec">


            <h2 style={{ fontSize: "2.5em", color: "#26639c", marginTop: "5em" }} >Access Forbidden</h2>
            <div >
                <div style={{ fontSize: "6em" }}>
                    <LockIcon style={{ fontSize: "inherit", color: "#26639c" }} />
                </div>
                <Typography style={{ fontStyle: "italic", marginTop: "1.5" }}> You are not allowed to view this page.</Typography>
                <Typography style={{ fontStyle: "italic", marginTop: "1.5" }}> Contact Support to
                
                        access.</Typography>

            </div>
        </section>
    );
}
