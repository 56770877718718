import React, { Component } from "react";
import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS,
    checkMemberGroup
} from "./auth-utils";
import ApplicationConstants from "./ApplicationConstants";

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
// const useRedirectFlow = true;

export default C =>
    class AuthProvider extends Component {
        constructor(props) {
            super(props);

            this.state = {
                account: null,
                error: null,
                emailMessages: null,
                graphProfile: null,
                idToken: null,
                isAuthorized: null,
                accessToken: null,
                sessionTimeOut: null,
                canPerformAction: null
            };
        }

        async acquireToken(request, redirect) {
            return msalApp.acquireTokenSilent(request).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msalApp.acquireTokenRedirect(request)
                        : msalApp.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
        }

        async onSignIn(redirect) {
            if (redirect) {
                return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
            }

            const loginResponse = await msalApp
                .loginPopup(GRAPH_REQUESTS.LOGIN)
                .catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

            if (loginResponse) {
                this.setState({
                    account: loginResponse.account,
                    error: null
                });
                console.log(this.state);
                const tokenResponse = await this.acquireToken(
                    GRAPH_REQUESTS.LOGIN
                ).catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

                if (tokenResponse) {
                    this.setState({
                        idToken: tokenResponse.idToken.rawIdToken,
                        accessToken: tokenResponse.accessToken
                    })
                    const graphProfile = await fetchMsGraph(
                        GRAPH_ENDPOINTS.ME,
                        tokenResponse.accessToken
                    ).catch(() => {
                        this.setState({
                            error: "Unable to fetch Graph profile."
                        });
                    });

                    if (graphProfile) {
                        this.setState({
                            graphProfile
                        });

                        const graphGroupResponse = await checkMemberGroup(GRAPH_ENDPOINTS.GROUP,
                            tokenResponse.accessToken, 'a52a1189-58cb-4606-a01f-5e159c6ed9f0').catch(() => {
                                this.setState({
                                    error: "Unable to authorize"
                                });
                            });

                        const accessToActions = await checkMemberGroup(GRAPH_ENDPOINTS.GROUP,
                            tokenResponse.accessToken, ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.AccessControlGroupId).catch(() => {
                                this.setState({
                                    error: "Unable to authorize"
                                });
                            });
                        //const data = await graphGroupResponse.json();
                       if (graphGroupResponse.value) {
                            graphGroupResponse.value.length > 0 ? this.setState({ isAuthorized: true, canPerformAction: false }) : this.setState({ isAuthorized: false, canPerformAction: false});
                            console.log(graphGroupResponse);
                        }

                        if (accessToActions.value) {
                            accessToActions.value.length > 0 ? this.setState({ isAuthorized: true, canPerformAction: true }) : this.setState({ canPerformAction: false, });
                            console.log(accessToActions);
                        }
                    }
                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
                        return this.readMail(tokenResponse.accessToken);
                    }
                    console.log(this.state);
                }
                console.log(this.state);

            }
        }

        onSignOut() {
            msalApp.logout();
        }

        async onRequestEmailToken() {
            const tokenResponse = await this.acquireToken(
                GRAPH_REQUESTS.EMAIL,
                useRedirectFlow
            ).catch(e => {
                this.setState({
                    error: "Unable to acquire access token for reading email."
                });
            });

            if (tokenResponse) {
                return this.readMail(tokenResponse.accessToken);
            }
        }

        async readMail(accessToken) {
            const emailMessages = await fetchMsGraph(
                GRAPH_ENDPOINTS.MAIL,
                accessToken
            ).catch(() => {
                this.setState({
                    error: "Unable to fetch email messages."
                });
            });

            if (emailMessages) {
                this.setState({
                    emailMessages,
                    error: null
                });
            }
        }

        async componentWillMount() {
            msalApp.handleRedirectCallback(error => {
                if (error) {
                    const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                    // setState works as long as navigateToLoginRequestUrl: false
                    this.setState({
                        error: errorMessage
                    });
                }
            });

            const response = await fetch(ApplicationConstants.EndPoints.GetEnvironmentVariable, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            ApplicationConstants.EnvironmentType = data;
            console.log(ApplicationConstants.EnvironmentType);
            GRAPH_REQUESTS.LOGIN.redirectUri = ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType].RedirectUri;
            msalApp.clientId = ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.ClientId;
            if (ApplicationConstants) {

            }
            const account = msalApp.getAccount();

            this.setState({
                account
            });

            if (account && this.state.graphProfile == null) {
                const tokenResponse = await this.acquireToken(
                    GRAPH_REQUESTS.LOGIN,
                    useRedirectFlow
                );

                if (tokenResponse) {
                    this.setState({ sessionTimeOut: tokenResponse.idToken?.expiration * 1000 - Date.now() })
                    console.log(tokenResponse.idToken);
                    this.setState({
                        idToken: tokenResponse.idToken.rawIdToken,
                        accessToken: tokenResponse.accessToken
                    })
                    const graphProfile = await fetchMsGraph(
                        GRAPH_ENDPOINTS.ME,
                        tokenResponse.accessToken
                    ).catch(() => {
                        this.setState({
                            error: "Unable to fetch Graph profile."
                        });
                    });

                    if (graphProfile) {
                        this.setState({
                            graphProfile
                        });

                        const graphGroupResponse = await checkMemberGroup(GRAPH_ENDPOINTS.GROUP,
                            tokenResponse.accessToken, ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.SecurityGroupId).catch(() => {
                                this.setState({
                                    error: "Unable to authorize"
                                });
                            });
                        //const data = await graphGroupResponse.json();

                        const accessToActions = await checkMemberGroup(GRAPH_ENDPOINTS.GROUP,
                            tokenResponse.accessToken, ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.AccessControlGroupId).catch(() => {
                                this.setState({
                                    error: "Unable to authorize"
                                });
                            });

                       if (graphGroupResponse.value) {
                            graphGroupResponse.value.length > 0 ? this.setState({ isAuthorized: true, canPerformAction: false }) : this.setState({ isAuthorized: false, canPerformAction: false});
                            console.log(graphGroupResponse);
                        }

                        if (accessToActions.value) {
                            accessToActions.value.length > 0 ? this.setState({ isAuthorized: true, canPerformAction: true }) : this.setState({ canPerformAction: false, });
                            console.log(accessToActions);
                        }
                    }
                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
                        return this.readMail(tokenResponse.accessToken);
                    }
                }
            }
            else if (!account) {
                this.onSignIn(true);
            }
            console.log(this.state)
        }

        render() {
            return (
                <C
                    {...this.props}
                    account={this.state.account}
                    emailMessages={this.state.emailMessages}
                    error={this.state.error}
                    graphProfile={this.state.graphProfile}
                    onSignIn={() => this.onSignIn(true)}
                    onSignOut={() => this.onSignOut()}
                    onRequestEmailToken={() => this.onRequestEmailToken()}
                    idToken={this.state.idToken}
                    isAuthorized={this.state.isAuthorized}
                    accessToken={this.state.accessToken}
                    sessionTimeOut={this.state.sessionTimeOut}
                    canPerformAction={this.state.canPerformAction}
                />
            );
        }
    };