import React, { useEffect } from 'react';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import SearchIcon from '@material-ui/icons/Search';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Collapse from '@material-ui/core/Collapse';
import './Card.css';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grid, TextField, Checkbox } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import { Suspense, lazy } from 'react';
import { Table, TableContainer, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom'
import FilterIcon from './FilterIcon.png';
import Constants from '../../ApplicationConstants.js';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
    },
    media: {
        height: 190,
    },
    avatar: {
        backgroundColor: '#2c3e50',
        height: '3em',
        width: '3em',
        marginTop: '0.8em'
    },
    sideContent: {
        width: '26%',
        fontSize: '2em',
    },
    mainContent: {
        width: '26%',
        fontSize: '2em',
    },
    Comment: {
        width: '26%',
        fontSize: '2em',
    },
    footer: {
        paddingLeft: '1em',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    input: {
        display: 'none',
    },
}));

const pingTableColumns = [

    {
        id: 'SystemName',
        label: 'System Name',
        minWidth: 180,
    },
    {
        id: 'Status',
        label: 'Status',
        minWidth: 180,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'Comment',
        label: 'Comment',
        minWidth: 180,
        align: 'right',
        format: value => value.toLocaleString(),
    },
];
const errorStatus = [3, 6, 9, 12, 15, 17];
const releasedStatus = [8, 20, 21];
const newProjectStatus = [0, 1, 2];
const activityTableColumns = [
    {
        id: 'projectActivityDisplayName',
        label: 'activity',
        minWidth: 170
    },
    {
        id: 'projectActivityStatusName',
        label: 'Status',
        minWidth: 130,
        align: 'left',
        format: value => value.toLocaleString(),
    },
    {
        id: 'projectActivityStartDate',
        label: 'Start Time',
        minWidth: 130,
        align: 'left',
        format: value => value.toLocaleString(),
    },
    {
        id: 'projectActivityEndDate',
        label: 'End Time',
        minWidth: 130,
    },
    {
        id: 'errorMessage',
        label: 'Description',
        minWidth: 230,
        align: 'left',
        format: value => value.toLocaleString(),
    },
];

async function getPKPN(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.GetPKPN, {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const pkpnData = await response.json();
    return pkpnData;
}

async function baseline(data, idToken, accessToken) {
    const response = await fetch("/baselineProjectLaunch", {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const pkpnData = await response.json();
    return pkpnData;
}

async function releaseProject(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.ReleaseProject, {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const releaseData = await response.json();
    return releaseData;
}

//async function releasePidPlusProject(data, idToken, accessToken) {
//    const response = await fetch(ApplicationConstants.ReleasePidPlusProject, {
//        method: 'POST',
//        body: (data),
//        headers: {
//            'Content-Type': 'application/json',
//            Authorization: `Bearer ${idToken}`,
//            'Authorization-AccessToken': `Bearer ${accessToken}`
//        }
//    });
//    const releasePidPlusData = await response.json();
//    return releasePidPlusData;
//}

async function onboardToSubsystems(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.OnboardToSubsystems, {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const onboardToSubsystems = await response.json();
    return onboardToSubsystems;
}

async function deleteProject(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.DeleteProject, {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    });
    const deleteData = await response.json();
    return deleteData;
}

async function downloadProject(data, idToken, accessToken) {
    const response = await fetch(ApplicationConstants.Download, {
        method: 'POST',
        body: (data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        },
        responseType: "arraybuffer"
    });
    const downloadData = await response.json();
    return downloadData;
}

async function uploadPkSpec(data, ProjectLaunchId, idToken, accessToken) {
    const url = ApplicationConstants.UploadPKSpec;
    const formData = new FormData();
    formData.append('PkSpec', data);
    formData.append('ProjectLaunchId', ProjectLaunchId)
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        },
    };
    const response = await axios.post(url, formData, config);
    console.log(response.data);
    return await response.data;
}

function Media(props) {
    const { loading = false, } = props;
    const groupCards = props.groupCards;
    const [projectActivityLogItemCollection, setprojectActivityLogItemCollection] = React.useState([]);
    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [open, setOpen] = React.useState(false);//For Popper
    const [cardOpen, setCardOpen] = React.useState(false);//For card Content
    const [openActivity, setOpenActivity] = React.useState(false);//For activity Table
    const [alertTitle, setAlertTitle] = React.useState('');
    const [alertBoxMessage, setAlertBoxMessage] = React.useState('');
    const [successfullAction, setSuccessfullAction] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [openLoading, setOpenLoading] = React.useState(false);
    const [ProjectLaunchId, setProjectLaunchId] = React.useState(props.projectID);
    const [file, setFile] = React.useState();
    const [upload, setUpload] = React.useState(false);
    const [successfullUpload, setSuccessfullUpload] = React.useState(false);
    let actionCount = 0;


    var MenuActions = [{ actionId: 0, actionName: 'Download', isActive: false, actionEnumValue: 'Download' },
    { actionId: 1, actionName: 'Upload', isActive: false, actionEnumValue: 'Upload' },
    { actionId: 2, actionName: 'Get PKPN', isActive: false, actionEnumValue: 'GetPKPN' },
    { actionId: 3, actionName: 'Baseline', isActive: false, actionEnumValue: 'Baseline' },
    { actionId: 4, actionName: 'Release', isActive: false, actionEnumValue: 'Release' },
    { actionId: 5, actionName: 'Reallocate', isActive: false, actionEnumValue: 'Reallocate' },
    { actionId: 6, actionName: 'Delete', isActive: false, actionEnumValue: 'Delete' },
    { actionId: 7, actionName: 'OnboardToSubsystems', isActive: false, actionEnumValue: 'OnboardToSubsystems' }
    //{ actionId: 8, actionName: 'OnboardToSubsystems', isActive: false, actionEnumValue: 'OnboardToSubsystems' },
    ];

    useEffect(() => {
        if (props.cardData && actionCount < 1) {
            MenuActions[1].actionName = releasedStatus.indexOf(props.cardData.projectStatus) !== -1 ? "Replanning Upload" : "Upload";
            handleProjectActionDisabled();
            actionCount++;
        }
    })
    useEffect(() => {
        setCardOpen(props.groupCards);
    }, [props.groupCards])



    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    }

    const inputFile = React.useRef(null);

    const handleUpload = async (event) => {
        if (event.target.files[0]) {
            setOpenLoading(true);
            setSnackBarMessage('Uploading');
            setOpenSnackBar(true);
            var uploadResponse = await uploadPkSpec(event.target.files[0], props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            console.log(uploadResponse);
            setOpenLoading(false);
            setOpenSnackBar(false);
            setAlertTitle('Upload');
            if (uploadResponse.returnCode !== 0) {
                setAlertBoxMessage(uploadResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(false);
            }
            else if (uploadResponse.returnCode === 0) {
                setAlertBoxMessage(uploadResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(true);
            }
        }
    }

    const handleMenuItemClick = async (event, index) => {

        console.log(props.cardData);
        setSelectedIndex(index);
        setOpen(false);
        console.log(props.cardData.projectLaunchId);
        props.setProjectID(props.cardData.projectLaunchId);
        setProjectLaunchId(props.cardData.projectLaunchId);
        props.setProductName(props.cardData.productName + "  " + props.cardData.productVersion + " " + props.cardData.productRelease);
        if (index === 0) {
            setSnackBarMessage('Download Started');
            setOpenSnackBar(true);
            setOpenLoading(true);
            var downloadResponse = await downloadProject(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            setOpenSnackBar(false);
            setOpenLoading(false);
            if (downloadResponse.returnCode === 0) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                const buf = Buffer.from(downloadResponse.base64, 'base64')
                var blob = new Blob([buf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = props.cardData.productName + '_' + props.cardData.productVersion + '_' + props.cardData.productRelease + '.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                setSnackBarMessage(Constants.AlertTitles.DownloadFailed);
                setOpenSnackBar(true);
            }
        }
        if (index === 1) {
            inputFile.current.click();

        }
        if (index === 2) {
            setSnackBarMessage('Get PKPN Invoked');
            setOpenSnackBar(true);
            setOpenLoading(true);
            var getPKPNResponse = await getPKPN(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            setAlertTitle(Constants.AlertTitles.GetPKPN);
            setOpenSnackBar(false);
            setOpenLoading(false);
            if (getPKPNResponse.returnCode === 0) {
                setAlertBoxMessage(getPKPNResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(true);
            }
            else {
                setAlertBoxMessage(getPKPNResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(false);

            }
        }
        if (index === 3) {

            if (props.versionDetail === "SubClass6") {
                setAlertTitle(Constants.AlertTitles.Baseline);
                setSnackBarMessage('Baseline Started');
                setOpenSnackBar(true);
                setOpenLoading(true);
                var releaseResponse = await baseline(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
                setOpenSnackBar(false);
                setOpenLoading(false);
                console.log(releaseResponse);
                if (releaseResponse.returnCode === 0) {
                    setAlertBoxMessage(releaseResponse.returnMessage);
                    setOpenAlert(true);
                    setSuccessfullAction(true);

                }
                else {
                    setAlertBoxMessage(releaseResponse.returnMessage);
                    setOpenAlert(true);
                    setSuccessfullAction(false);

                }
            }
            else {
                props.setReallocation(false);
                props.setNav(6);
            }
        }
        if (index === 4) {
            setSnackBarMessage('Release Started');
            setOpenSnackBar(true);
            setOpenLoading(true);
            var releaseResponse = await releaseProject(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            setAlertTitle('Release');
            setOpenSnackBar(false);
            setOpenLoading(false);
            if (releaseResponse.returnCode === 0) {
                setAlertBoxMessage(releaseResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(true);

            }
            else {
                setAlertBoxMessage(releaseResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(false);
            }
        }
        if (index === 5) {

            props.setReallocation(true);

            if (props.versionDetail === 'SubClass3')
                props.setNav(7);
            else
                props.setNav(5);


        }
        if (index === 6) {
            setSnackBarMessage('Deleting');
            setOpenLoading(true);
            setOpenSnackBar(true);
            var deleteResponse = await deleteProject(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            setAlertTitle(Constants.AlertTitles.Delete);
            setOpenLoading(false);
            setOpenSnackBar(false);

            if (deleteResponse.returnCode === 0) {
                setAlertBoxMessage(deleteResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(true);

            }
            else {
                setAlertBoxMessage(deleteResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(false);

            }
        }
        //if (index === 7) {
        //    setSnackBarMessage('Releasing to PidPlus Started');
        //    setOpenSnackBar(true);
        //    setOpenLoading(true);
        //    var releaseResponse = await releasePidPlusProject(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
        //    setAlertTitle('ReleaseToPidPlus');
        //    setOpenSnackBar(false);
        //    setOpenLoading(false);
        //    if (releaseResponse.returnCode === 0) {
        //        setAlertBoxMessage(releaseResponse.returnMessage);
        //        setOpenAlert(true);
        //        setSuccessfullAction(true);

        //    }
        //    else {
        //        setAlertBoxMessage(releaseResponse.returnMessage);
        //        setOpenAlert(true);
        //        setSuccessfullAction(false);
        //    }
        //}

        if (index === 7) {
//            props.setReallocation(true);
            setSnackBarMessage('Onboarding To Subsystems Started');
            setOpenSnackBar(true);
            setOpenLoading(true);
            var releaseResponse = await onboardToSubsystems(props.cardData.projectLaunchId, props.accountInfo.idToken, props.accountInfo.accessToken);
            setAlertTitle('OnboardingToSubsystems');
            setOpenSnackBar(false);
            setOpenLoading(false);
            if (releaseResponse.returnCode === 0) {
                setAlertBoxMessage(releaseResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(true);

            }
            else {
                setAlertBoxMessage(releaseResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullAction(false);
            }
        }

    };

    const handleAlertClose = () => {
        props.refreshData();
        props.setNav(3);
        setOpenAlert(false);
    };


    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleClickOpenActivity = () => {
        console.log(props.cardData)
        console.log(props.cardData.projectStatus)
        setprojectActivityLogItemCollection(props.cardData.projectActivityLogItemCollection);
        setOpenActivity(true);
    };
    const handleCloseActivity = () => {
        setOpenActivity(false);
    };
    const handleDropDown = () => {
        //console.log(cardOpen);
        //console.log(props.cardsExpanded);
        //  console.log(props.versionDetail);
        setCardOpen(!cardOpen);
        //cardOpen = !cardOpen;
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleProjectActionDisabled = (index) => {
        console.log(props.canPerformAction)
        if (props.cardData.rowCount == 0) {
            MenuActions[2].IsActive = false;
            props.cardData.projectActionCollection["GetPKPN"].isActive = false;
            
            //props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
        }
        else {
            if (props.cardData.projectStatusName == "Released") {
                //MenuActions[9].IsActive = true;
                //props.cardData.projectActionCollection["OnboardToSubsystems"].isActive = true;
            }
            // Get PKPN button enable code
            if ((props.cardData.projectStatusName == "In Planning") || (props.cardData.projectStatusName == "Reallocation in progress")) {
                console.log(props.cardData)
                console.log(props.cardData.projectStatus)
                let count = props.cardData.projectActivityLogItemCollection.length;
                if (count > 0) {
                    let item = props.cardData.projectActivityLogItemCollection[count - 1];
                    console.log(item)
                    if (item.projectActivityName == "Upload" && item.projectActivityStatusName == "Succeeded") {
                        props.cardData.projectActionCollection["GetPKPN"].isActive = true;
                        // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                    }
                    else {
                        props.cardData.projectActionCollection["GetPKPN"].isActive = false;
                        //props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                    }

                    if (item.projectActivityName == "PMCTValidation" && item.projectActivityStatusName != "Failed") {
                        props.cardData.projectActionCollection["Delete"].isActive = false;
                        props.cardData.projectActionCollection["Upload"].isActive = false;
                       // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                    }

                    if (item.projectActivityName == "PKPNCreation") {
                        props.cardData.projectActionCollection["Delete"].isActive = false;
                       // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;

                        if (item.projectActivityStatusName != "Failed") {
                            props.cardData.projectActionCollection["Upload"].isActive = false;
                           // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                        }
                    }
                }
                else {
                    props.cardData.projectActionCollection["GetPKPN"].isActive = false;
                    // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                }
            }
            else if ((props.cardData.projectStatusName == "Reallocation In Planning" || props.cardData.projectStatusName == "In Planning")) {
                let count = props.cardData.projectActivityLogItemCollection.length;
                if (count > 0) {
                    var item;
                    props.cardData.projectActivityLogItemCollection.forEach(function (x) {
                        if (x.projectActivityName == "PKPNCreation" && x.projectActivityStatusName == "Succeeded") {
                            item = x;
                        }
                    })

                    if (item != null) {
                        props.cardData.projectActionCollection["Delete"].isActive = false;
                        props.cardData.projectActionCollection["Upload"].isActive = false;
                       // props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;

                        // If not replanning\reallocation item and PKPNCreation succeeded, then enable upload to facilitate pkpn description change To DO
                    }
                    else {
                        props.cardData.projectActionCollection["Delete"].isActive = true;
                        props.cardData.projectActionCollection["Upload"].isActive = true;
                        //props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
                    }
                }

            }
            else {
                props.cardData.projectActionCollection["GetPKPN"].isActive = false;
                //props.cardData.projectActionCollection["PidPlusRelease"].isActive = false;
            }
}

console.log(MenuActions);
    }

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const Alerts = (<Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="AlertDialog">
        <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullAction ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{alertTitle}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {alertBoxMessage.split('\n').map(i => {
                    return <p>{i}</p>
                })}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleAlertClose} color="primary" autoFocus>
                Close
                    </Button>
        </DialogActions>
    </Dialog>)

    const activityTable = (
        <div>
            <TableContainer classeName="container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {activityTableColumns.map(column => (
                                <TableCell
                                    id="TableHeader"
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.cardData ? props.cardData.projectActivityLogItemCollection.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.projectActivityLogID}>
                                    {activityTableColumns.map(column => {
                                        if ((column.id == "projectActivityStartDate" || column.id == "projectActivityEndDate") && row[column.id] != null) {
                                            row[column.id] = new Date(row[column.id]).toLocaleString();
                                        }
                                        const value = row[column.id];
                                        return (
                                            <TableCell id='ActivityStatusTableBody' key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

    const ActivityDialog = (
        <div>
            <Dialog onClose={handleCloseActivity} aria-labelledby="customized-dialog-title" open={openActivity} >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> Activity Status
                                    </DialogTitle>
                <DialogContent id="customized-dialog-content" dividers>
                    {activityTable}
                </DialogContent>
                <DialogActions id ="customized-dialog-footer">
                    <Button autoFocus onClick={handleCloseActivity} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);

    return (
        <Card className={classes.card} id="contrast">
            {Alerts}
            <CardHeader id="headerLayout"
                avatar={
                    loading ? (
                        <div id="avatar" style={{ padding: '10px' }}>
                            <Skeleton id="statusRepresentation" variant="rect" width={10} height={40} />
                            <Skeleton variant="circle" width={40} height={40} />
                        </div>
                    ) : (
                            <div onClick={handleDropDown} id="avatar">
                                <div id="statusRepresentation" style={(releasedStatus.indexOf(props.cardData.projectStatus) !== -1) ? { backgroundColor: '#097309' } :
                                    (errorStatus.indexOf(props.cardData.projectStatus) !== -1) ? { backgroundColor: '#a90020' } : (newProjectStatus.indexOf(props.cardData.projectStatus) !== -1) ? { backgroundColor: '#6daabb' } : { backgroundColor: '#f9d513' }} >
                                </div>
                                <Avatar
                                    aria-label="product"
                                    className={classes.avatar}>
                                    <div style={{ fontSize: '2em' }}>
                                        {props.cardData.productName[0]}
                                    </div>
                                </Avatar>
                            </div>
                        )
                }
                action={
                    loading ? null : (
                        <React.Fragment>

                            <Grid container direction="row" alignItems="center" marginTop='1em' id='cardActionBar '>
                                <Grid item xs={12}>
                                    {cardOpen ?
                                        <IconButton aria-label="settings" ref={anchorRef} onClick={handleDropDown} >
                                            <ArrowDropUpIcon style={{ fontSize: '2.5rem' }} />
                                        </IconButton> :
                                        <IconButton aria-label="settings" ref={anchorRef} onClick={handleDropDown} >
                                            <ArrowDropDownIcon style={{ fontSize: '2.5rem' }} />
                                        </IconButton>}
                                    <IconButton aria-label="settings" ref={anchorRef} onClick={handleToggle} >
                                        <MoreVertIcon style={{ fontSize: '2.5rem' }} />
                                    </IconButton>
                                    <Popper id='popper' open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList id="split-button-menu">
                                                            {MenuActions.map((action, index) => (
                                                                <div>
                                                                    {index == 1 ? <React.Fragment>
                                                                        <input
                                                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                            className={classes.input}
                                                                            id="uploadPkSpec"
                                                                            onChange={handleUpload.bind(this)}
                                                                            type="file"
                                                                            tabIndex="0"
                                                                           
                                                                        />
                                                                        <label className='uploadWidth' htmlFor="uploadPkSpec">
                                                                            <MenuItem
                                                                                key={action.actionId}
                                                                                disabled={(props.cardData.projectActionCollection[action.actionEnumValue] ? !props.cardData.projectActionCollection[action.actionEnumValue].isActive : true)||!props.canPerformAction}
                                                                                selected={index === selectedIndex}
                                                                                component="span"
                                                                                tabIndex="0"
                                                                            >
                                                                                {action.actionName}
                                                                            </MenuItem>
                                                                        </label></React.Fragment> : 
                                                                            index == 0 ? <MenuItem
                                                                                key={action.actionId}
                                                                                disabled={props.cardData.projectActionCollection[action.actionEnumValue] ? !props.cardData.projectActionCollection[action.actionEnumValue].isActive : true}
                                                                                selected={index === selectedIndex}
                                                                                onClick={event => handleMenuItemClick(event, index)}
                                                                                tabIndex="0"
                                                                    >  {action.actionName}
                                                                    </MenuItem>:
                                                                        <MenuItem
                                                                            key={action.actionId}
                                                                            disabled={ (props.cardData.projectActionCollection[action.actionEnumValue] ? (!props.cardData.projectActionCollection[action.actionEnumValue].isActive) : true ) || !props.canPerformAction}
                                                                            selected={index === selectedIndex}
                                                                            onClick={event => handleMenuItemClick(event, index)}
                                                                            tabIndex="0"
                                                                        >
                                                                            {action.actionName}
                                                                        </MenuItem>}
                                                                    {(index == 1) || (index == 4) || (index == 5) ? <Divider /> : null}
                                                                    
                                                                </div>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                title={loading ? <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} /> : <div onClick={handleDropDown} style={{ fontSize: '2.5em' }} >{props.cardData.productName}</div>}

                subheader={loading ? <Skeleton height={10} width="40%" /> : <div onClick={handleDropDown} style={{ fontSize: '1.5em' }}>{props.cardData.productVersion} {props.cardData.productRelease}</div>} />
            <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleUpload.bind(this)} />

            <Collapse in={cardOpen} >
                < div id="cardContent">
                    <CardContent className={classes.sideContent}>
                        {loading ? (
                            <React.Fragment>
                                <Skeleton height={10} style={{ marginBottom: 6 }} />
                                <Skeleton height={10} width="80%" />
                            </React.Fragment>
                        ) : (
                                <Typography id='cardBody' variant="body2" color="textSecondary" component="p">
                                    <div id='contentHeader'>
                                        Launch Type<br />
                                        Use PK Config<br />
                                        Contact<br />
                                    </div>
                                    <div id='contentValues'>
                                        {props.cardData.launchType}<br />
                                        {props.cardData.launchType ? <React.Fragment>Yes</React.Fragment> : <React.Fragment>No</React.Fragment>}<br />
                                        {props.cardData.hashContactList}<br />
                                    </div>
                                </Typography>)}
                    </CardContent>
                    <CardContent className={classes.mainContent}>
                        {loading ? (
                            <React.Fragment>
                                <Skeleton height={10} style={{ marginBottom: 6 }} />
                                <Skeleton height={10} width="80%" />
                            </React.Fragment>
                        ) : (
                                <Typography id='cardBody' variant="body2" color="textSecondary" component="p">
                                    <div id='contentHeader'>
                                        Status<br />
                                        Row Count<br />
                                    </div>
                                    <div id='contentValues'>
                                        {props.cardData.projectStatusName}<br />
                                        {props.cardData.rowCount}<br />
                                    </div>
                                </Typography>)}
                    </CardContent>
                    <CardContent className={classes.mainContent}>
                        <div id="ButtonAlign">
                            {loading ? <Skeleton height={10} width="80%" />
                                :
                                <section>
                                    <Button id="buttonStyle2" onClick={handleClickOpenActivity} marginTop='1em' variant='contained' size="large">Show Activity Status</Button>
                                    {ActivityDialog}
                                </section>
                            }
                        </div>
                    </CardContent>
                </div>
                <CardContent id='footerContainer'>
                    {loading ? (
                        <React.Fragment>
                            <Skeleton height={10} style={{ marginBottom: 6 }} />
                            <Skeleton height={10} width="80%" />
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <Typography id='cardFooter' variant="body2" color="textSecondary" component="p">
                                    <div>
                                        Uploaded By<br />
                                        Uploaded Date<br />
                                    </div>
                                    <div className={classes.footer}>
                                        {props.cardData.modifiedBy}<br />
                                        {new Date(props.cardData.modifiedDate).toLocaleString()}<br />
                                    </div>
                                </Typography>
                            </React.Fragment>)}
                </CardContent>
            </Collapse>
            {BottomSnackBar}
            <Backdrop className={classes.backdrop} open={openLoading} >
                <CircularProgress />
            </Backdrop>
        </Card>
    );
}

function PingData(props) {

    const [openComments, setOpenComments] = React.useState(false);

    const openCommentsBox = () => {
        setOpenComments(true);

    };
    const closeCommentBox = () => {
        setOpenComments(false);
    };
    return (
        <React.Fragment>
            <TableBody>
                <TableRow>
                    <TableCell
                        style={{ minWidth: 180 }}> {props.name}</TableCell>
                    <TableCell
                        style={{ minWidth: 180 }}> {!props.success ? <CheckCircleIcon style={{ color: '#097309', }} /> : <CancelIcon style={{ color: '#a90020', }} />}</TableCell>
                    <TableCell
                        style={{ minWidth: 180 }}> {openComments ? < Button id="outlined" onClick={closeCommentBox}>Close </Button> : < Button id="outlined" onClick={openCommentsBox}>Show </Button>}</TableCell>
                </TableRow>
            </TableBody>
            <Collapse in={openComments}>

                <div>
                    <Typography align="right">
                        {props.success}
                    </Typography>

                </div>

            </Collapse>
        </React.Fragment>
    );
}

function Legend(props) {

    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        setLoading(props.openLoading);
    }, [props.openLoading])

    //var pingResponse=props.
    const PingTable = (
        <div>
            <TableContainer classeName="container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {pingTableColumns.map(column => (
                                <TableCell
                                    id="TableHeader"
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

        </div>
    );

    const PingDialog = (
        <div >
            <Dialog onClose={props.handleClosePing} aria-labelledby="customized-dialog-title" open={props.pingDialog} >
                <DialogTitle id="customized-dialog-title" onClose={props.handleClosePing}>
                    <div id="statusRepresentationAlert" style={{ backgroundColor: '#26639c' }} /> PING
                </DialogTitle>
                <DialogContent dividers>

                    {PingTable}
                    <PingData name="PPP System" success={props.pingData.pppException} />
                    <PingData name="SLK System" success={props.pingData.slkException} />
                    <PingData name="AVS System" success={props.pingData.avsException} />

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleClosePing} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);


    return (
        <div id="SubHeaderRow">
            <div id="Legend">

                <div id="colorcode">
                    <StopIcon id="OK" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        Success
                </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="Fail" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        Error
                </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="Processing" />

                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        In Progress
                                </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="InPlanning" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        In Planning
                                </Typography>
                </div>
            </div>
            <div>
                <div id="GroupAll">

                    <Button variant="contained" id="PPPButtonGroup" onClick={props.clickGroupCards}  >{props.groupCards ? "Collapse" : "Expand"}</ Button>
                    <Button variant="contained" id="PPPButtonRefresh" onClick={props.clickRefresh}>Refresh</Button>
                    <Button variant="contained" id="PPPButtonPing" onClick={props.clickPingButton} disabled>Ping</Button>

                    {PingDialog}

                </div>

            </div>
        </div>
    )
}

var ApplicationConstants = {};

//function Legend() {
//    const classes = useStyles();
//    return (
//        <div id="Legend">
//            <div id="colorcode">
//                <StopIcon id="Released" />
//                <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
//                    Released
//                </Typography>
//            </div>
//            <div id="colorcode">
//                <StopIcon id="Error" />
//                <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
//                    Error
//                </Typography>
//            </div>
//            <div id="colorcode">
//                <StopIcon id="InProgress" />
//                <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
//                    In Progress
//                </Typography>
//            </div>
//            <div id="colorcode">
//                <StopIcon id="InPlanning" />
//                <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
//                    In Planning
//                </Typography>
//            </div>
//        </div>
//    )
//}

export class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardDataSet: [],
            currentRender: [],
            currentCardCount: 0,
            handleUpload: this.props.handleUpload,
            handleBaseline: this.props.handleBaseline,
            openSearch: false,
            searchText: '',
            pingDialog: false,
            groupCards: true,
            pingData: { "isPPPDBReachable": false, "isSLKSystemReachable": false, "isAVSSystemReachable": false, "pppException": "Cannot set the AccessToken property if 'UserID', 'UID', 'Password', or 'PWD' has been specified in connection string.", "slkException": "Configuration files are not supported.", "avsException": "Could not find file 'C:\\ProductKeyRangePropertyGet.xml'." },
            openLoading: false

        };
        this.clickRefresh = this.clickRefresh.bind(this);
        this.populateMore = this.populateMore.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.clickGroupCards = this.clickGroupCards.bind(this);

    }

    clickGroupCards = () => {
        const groupCards = !this.state.groupCards;
        this.setState({ ...this.state, groupCards });
        console.log(this.state.groupCards);
    };

    clickSearchBar = () => {
        const openSearch = !this.state.openSearch;
        this.setState({ ...this.state, openSearch });
    };

    clickPingButton = async () => {
        console.log("Clicked");
        this.setState({ openLoading: true, pingDialog: true });
        var data = await pingPPPSubSystems(this.props.accountInfo.idToken, this.props.accountInfo.accessToken);
        console.log(data);
        this.setState({ ...this.state, pingData: data, openLoading: false });
    };

    handleClosePing = () => {
        const pingDialog = false;
        this.setState({ ...this.state, pingDialog });
    };

    clickRefresh() {
        this.setState({
            ...this.state, cardDataSet: [],
            currentRender: [],
            currentCardCount: 0
        });
        this.populateCardData();
    }
    componentWillMount() {
        ApplicationConstants = Constants.EndPoints[this.props.versionDetail];
        this.populateCardData();
    }

    componentDidMount() {
        /* window.addEventListener('scroll', window.addEventListener('scroll', function () {
             window.pageYOffset > 900 ? this.populateMore:null;
         }));*/
        window.addEventListener('scroll', this.handleScroll, false);
        this.searchButton();

    }

    componentDidUnmont() {
        window.addEventListener('scroll', this.handleScroll, false);

    }

    async populateCardData() {
        const response = await fetch(ApplicationConstants.GetDashboardData, {
            method: 'POST',
            body: (this.props.versionDetail === "SubClass3" ? 0 : 1),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.accountInfo.idToken}`,
                'Authorization-AccessToken': `Bearer ${this.props.accountInfo.accessToken}`
            }
        })

        if (response.status === 401 || response.status === 403) {
            this.setState({
                isAuthorized: false
            });
        }
        console.log(response);
        console.log(this.state)
        if (response.status == 200) {
            const data = await response.json();
            let currentCardCount = this.state.currentCardCount + 5;
            let currentRender = data.slice(0, currentCardCount);
            this.setState({ currentCardCount, cardDataSet: data, currentRender });
        }
    }

    handleScroll(e) {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        const windowBottom = windowHeight + window.pageYOffset + 10;

        if (windowBottom >= docHeight && this.state.searchText === '') {
            this.populateMore();
        }
    }

    refreshData(e) {
        this.populateCardData();
    }

    populateMore(e) {
        // if the user scrolled far enough (<100px to the end)
        let currentCardCount = this.state.currentCardCount + 10;
        let currentRender = this.state.cardDataSet.slice(0, currentCardCount);
        this.setState({ currentCardCount, currentRender });

    }

    handleSearchBarChange(event) {

        this.setState({ searchText: event.target.value });
        if (event.target.value != '') {
            var searchList = this.state.cardDataSet.filter(cardData => {
                const item = cardData.productName.toLowerCase();
                const filter = event.target.value.toLowerCase();

                return item.includes(filter);
            })
            console.log(searchList);
            this.setState({
                currentRender: searchList, currentCardCount: 0
            });
        }
        else {
            console.log(event.target.value)
            this.setState({ currentCardCount: 0 })
            this.populateMore();
        }
    }

    handleReset(event) {
        console.log(event.target.value)
        this.setState({ currentCardCount: 0 })
        this.populateMore();
    }
    searchButton() {
        const input = document.getElementById("search-input");
        const searchBtn = document.getElementById("search-btn");

        const expand = () => {
            searchBtn.classList.toggle("close");
            input.classList.toggle("square");
        };
        if (searchBtn) {
            searchBtn.addEventListener("click", expand);
        }
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <header role="heading" className="Header" aria-level="1">
                        <h1>History - {this.props.versionDetail === "SubClass3" ? "PrePK2009" : "PK2009"}</h1>
                        <form id="content">
                            <input type="text" name="input" class="inputSearchBar" id="search-input" onChange={event => this.handleSearchBarChange(event)} placeholder="Search products" />
                            <button type="reset" class="search" onClick={event => this.handleReset(event)} id="search-btn" aria-label="Search"></button>
                        </form>
                    </header>

                    <div>
                        <Legend groupCards={this.state.groupCards} clickGroupCards={this.clickGroupCards} handleClosePing={this.handleClosePing} clickPingButton={this.clickPingButton} pingDialog={this.state.pingDialog} pingData={this.state.pingData} clickRefresh={this.clickRefresh} />
                        {this.state.currentRender.map((cardData, index) =>
                            <Media groupCards={this.state.groupCards} refreshData={this.refreshData} openLoading={this.state.openLoading} versionDetail={this.props.versionDetail} projectID={this.props.projectID} pingData={this.state.pingData} setProductName={this.props.setProductName} cardData={cardData} setNav={this.props.setNav} setProjectID={this.props.setProjectID} projectID setReallocation={this.props.setReallocation} accountInfo={this.props.accountInfo} canPerformAction={this.props.accountInfo.canPerformAction} />
                        )}
                        {this.state.currentRender.length % 5 === 0 ? <Media loading /> :
                            null}
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

async function pingPPPSubSystems(idToken, accessToken) {

    const response = await fetch(ApplicationConstants.PingPPP, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Authorization-AccessToken': `Bearer ${accessToken}`
        }
    })
    const data = await response.json();
    return data;
}