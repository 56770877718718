export default {
    EnvironmentType: "UAT",
    Version: "",
    EndPoints: {
        GetEnvironmentVariable: "Base/GetEnvironmentVariable",
        SubClass6: {
            GetDashboardData: "/getcarddatabysubclass",
            Version_Name: "PK 2009 ",
            PingPPP: "/pingPPPSubSystems",
            UploadPKSpec: "/uploadpkspec",
            ReleaseProject: "/releaseProjectLaunch",
            DeleteProject: "/deleteprojectlaunch",
            Baseline: "/baselineProjectLaunch",
            GetPKPN: "/getPKPN",
            Download: "/downloadProject",
            SetProductProfile: "setProductProfile",
            UpdateDomainValues: "/updateDomainValues",
            GetProductProfiles: "/getproductprofiles",
            GetActivationSystemLists: "/getActivationSystemList",
            GetDomainDataValues: "/getDomainDataValues",
            NewProjectLaunch: "/newprojectreleasebysubclass",
            IsOverlap: "/isOverlapRange",
            Getnewpkpnforbaseline: "/getnewpkpnforbaseline",
            Getallpkpndata: "/getallpkpndataforreallocation",
            IsReplanning: "/isReplanningProject",
            GetAvailableRanges: "/getAvailableRangeDetails",
            GetPrePKRangeDetails: "/getPrePKRangeDetails",
            Reallocate: "/reallocateProjectLaunch",
            SubmitReallocation: "/submitReallocationData",
            //ReleasePidPlusProject: "/releasePidPlusProject",
            OnboardToSubsystems:"/onboardToSubsystems"
        },
        SubClass3: {
            GetDashboardData: "/getcarddatabysubclass",
            Version_Name: "Pre PK 2009",
            PingPPP: "/pingPPPSubSystems",
            UploadPKSpec: "/uploadpkspec",
            ReleaseProject: "/releaseProjectLaunch",
            DeleteProject: "/deleteprojectlaunch",
            Baseline: "/baselinePid3ProjectLaunch",
            GetPKPN: "/getPKPN",
            Download: "/downloadProject",
            SetProductProfile: "setProductProfile",
            UpdateDomainValues: "/updateDomainValues",
            GetProductProfiles: "/getproductprofiles",
            GetActivationSystemLists: "/getActivationSystemList",
            GetDomainDataValues: "/getDomainDataValues",
            NewProjectLaunch: "/newprojectreleasebysubclass",
            IsOverlap: "/isOverlapRange",
            Getnewpkpnforbaseline: "/getnewpkpnforbaseline",
            Getallpkpndata: "/getallpkpndataforreallocation",
            IsReplanning: "/isReplanningProject",
            GetAvailableRanges: "/getAvailableRangeDetails",
            GetPrePKRangeDetails: "/getPrePKRangeDetails",
            Reallocate: "/reallocateProjectLaunch",
            SubmitReallocation: "/submitReallocationData",            
           // ReleasePidPlusProject: "/releasePidPlusProject",
            OnboardToSubsystems: "/onboardToSubsystems"
        }
    },
    AppSettings: {
        Dev: {
            ClientId: "47b68595-1bca-4194-be75-87f5cc6bf191",
            RedirectUri: "https://localhost:44323",
            SecurityGroupId: "a52a1189-58cb-4606-a01f-5e159c6ed9f0",
            AccessControlGroupId:"1e6cc7b8-b275-4f7a-a059-6231548c9523",
        },
        UAT: {
            ClientId: '47b68595-1bca-4194-be75-87f5cc6bf191',
            RedirectUri: "https://portal-wus-uat-pppv2.azurewebsites.net",
            SecurityGroupId: "a52a1189-58cb-4606-a01f-5e159c6ed9f0",
            AccessControlGroupId: "1e6cc7b8-b275-4f7a-a059-6231548c9523",
        },
        PRODEUS: {
            ClientId: "863eda32-4e23-424a-8378-1c7b34c0db11",
            RedirectUri: "https://portal-eus-prod-pppv2.azurewebsites.net",
            SecurityGroupId: "a52a1189-58cb-4606-a01f-5e159c6ed9f0",
            AccessControlGroupId: "1e6cc7b8-b275-4f7a-a059-6231548c9523",
        },
        PRODSCUS: {
            ClientId: "7cefb051-7f90-4bf9-b7b1-1fb1740cfd55",
            RedirectUri: "https://portal-scus-prod-pppv2.azurewebsites.net",
            SecurityGroupId: "a52a1189-58cb-4606-a01f-5e159c6ed9f0",
            AccessControlGroupId: "1e6cc7b8-b275-4f7a-a059-6231548c9523",
        }
    },
    AlertTitles: {
        RangeSelection: "Range Selection",
        Overlapping: "Overlapping",
        GetPKPN: "Get PKPN",
        Baseline: "Baseline",
        Release: "Release",
        Delete: "Delete",
        Upload: "Upload",
        DownloadFailed:"Download Failed"
    },
    AlertMessages: {

        Range_SerialNumberFault: "End serial number cannot be less than the start serial number.",
        Range_Overlapping: "The selected range will result in an overlapping scenario. Do you wish to proceed ?",
        ActionCompleted: "Action Completed Successfully.",
        Reallocation_PKPNConflict: "Source and Destination PKPN Conflict : Cannot be same",
        q: "Group ID Conflict : Group ID for source and destination PKPN is different",
        Reallocation_RangeError: "Range Conflict : source start is smaller than destination ",
        Reallocation_TransferError: "Transfer value greater than Lifetime forecast",
        Reallocation_LaunchQuantityError: "Launch Quantity greater than available keys",
        Reallocation_MultipleError: "The following PKPN is/are used in more than one request :",
        Reallocation_CannotSubmit: "Invalid or Incomplete Input. Please fill the required information correctly."
    }
}


