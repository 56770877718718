import React, { useState} from 'react';
import { Col, Row, Form, FormGroup, Label, Input, FormText, Alert, Card, Jumbotron, Container, CardGroup } from 'reactstrap';
import Range from '../Range/Range';
import './PartNumber.css';
import { Button, Fab, TextField, Checkbox, Paper } from '@material-ui/core';
import  SaveIcon  from '@material-ui/icons/Save';
import Refresh  from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
    { id: 'partNumber', label: 'Part Number', minWidth: 170 },
    {
        id: 'startSerialNumber',
        label: 'Start Serial Number',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'endSerialNumber',
        label: 'End Serial Number',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    { id: 'description', label: 'Description', minWidth: 170 },
];

const retailRows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const oemRows = [];

function createData(partNumber, startSerialNumber, endSerialNumber, description) {
    return { partNumber, startSerialNumber, endSerialNumber, description};
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const newPkpn = [];

const PartNumber = (props) => {
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    return (
        <Paper elevation={24}>
            <header role="heading" className="Header mb-3">
                <h1>PPP Release</h1>
            </header>
            <Form className="labelFont pageProp">
                <FormGroup row className="align-items-center ">
                    <Col sm={3}>
                        <TextField className="textboxProperties" id="outlined-basic" label="New" variant="outlined" />
                    </Col>
                    <Col sm={1}>
                        <Button size="large" variant="contained" color="primary">Load</Button>
                    </Col>
                    <Col sm={3} className="col text-right">
                        <TextField className="textboxProperties" id="outlined-basic" label="Changed" variant="outlined" />
                    </Col>
                    <Col sm={1} className="align-items-top">
                        <Button size="large" variant="contained" color="primary">Load</Button>
                    </Col>
                    <Col sm={2} className="col text-right">
                        <Label><b>PMCT Import</b></Label>
                    </Col>
                    <Col sm={1} className="col text-left">
                        <Button size="large" variant="contained" color="primary" startIcon={<Refresh />} >Refresh</Button>
                    </Col>
                </FormGroup>
                <CardGroup>
                    <Card className="card col-sm-10 cardProperties cardShadow" >
                        <Row form>
                            <Col sm={1}>
                                <FormGroup>
                                    <Label for="partInfo"><b>Part Info</b></Label>
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Alert color="primary" className="col text-center"><b>SPK Data</b></Alert>
                                </FormGroup>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Alert color="primary" className="col text-center"><b>PMCT Data</b></Alert>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col sm={1}>
                                <Label for="partInfo">KeyPart ID</Label>
                            </Col>
                            <Col sm={5}>
                                <TextField disabled size="small" className="textboxProperties" size="small"id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={5} className="col text-center">
                                <Button variant="contained" size="sm">Ignore PMCT Data</Button>
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col sm={1}>
                                <Label for="partInfo">Part Number</Label>
                            </Col>
                            <Col sm={5}>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={5}>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col sm={1}>
                                <Label for="partInfo">Group ID</Label>
                            </Col>
                            <Col sm={5}>
                                <TextField disabled size="small" className="textboxProperties"id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col sm={1} className="col text-center">
                                <Checkbox disabled value="disabled" inputProps={{ 'aria-label': 'disabled checkbox' }} />
                            </Col>
                            <Col sm={5} className="col text-center">
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col md={1}>
                                <Label for="partInfo">Upgrade</Label>
                            </Col>
                            <Col sm={5}>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col sm={1} className="col text-center">
                                <Checkbox disabled value="disabled" inputProps={{ 'aria-label': 'disabled checkbox' }} />
                            </Col>
                            <Col sm={5} className="col text-center">
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col sm={1}>
                                <Label for="partInfo">Sub Class</Label>
                            </Col>
                            <Col md={5}>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col sm={1} className="col text-center">
                                <Checkbox disabled value="disabled" inputProps={{ 'aria-label': 'disabled checkbox' }} />
                            </Col>
                            <Col sm={5} className="col text-center">
                                <TextField disabled size="small" className="textboxProperties"id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col md={1}>
                                <Label for="partInfo">Description</Label>
                            </Col>
                            <Col md={5}>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                            <Col md={1} className="col text-center">
                                <Checkbox disabled value="disabled" inputProps={{ 'aria-label': 'disabled checkbox' }} />
                            </Col>
                            <Col md={5} >
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                        <Row form className="tableProperties">
                            <Col md={1}>
                            </Col>
                            <Col md={5} className="col text-center">
                                <Button variant="contained">Update From PMCT</Button>
                            </Col>
                            <Col md={1}>
                            </Col>
                            <Col md={2} >
                                <label>PMCT Status</label>
                            </Col>
                            <Col md={3} className="col text-right" >
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>

                        <Row form className="tableProperties">
                            <Col md={7}>
                            </Col>
                            <Col md={2} >
                                <label>Changed</label>
                            </Col>
                            <Col md={3} className="col text-right">
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="" variant="filled" />
                            </Col>
                        </Row>
                    </Card>
                    <Card className="card col-sm-2 cardProperties cardShadow">
                        <Form>
                            <FormGroup>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="Created Date" value="" variant="outlined" />
                            </FormGroup>
                            <FormGroup>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="Created By" value="" variant="outlined" />
                            </FormGroup>
                            <FormGroup>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="Last Modified Date" value="" variant="outlined" />
                            </FormGroup>
                            <FormGroup>
                                <TextField disabled size="small" className="textboxProperties" id="filled-basic" label="Last Modified By" value="" variant="outlined" />
                            </FormGroup>
                        </Form>
                    </Card>

                </CardGroup>
                <FormGroup row className="align-items-center bottomBreak keyRange">
                    <Col md={2}>
                        <Label for="keyRange">Key Range</Label>
                    </Col>
                    <Col md={9} className="col text-right">
                        <Input type="textarea" style={{ minHeight: 100 }} name="text" id="keyRange" readOnly />
                    </Col>
                    <Col md={1} className="col text-center">
                        <Fab variant="contained" href="/range">Modify</Fab>
                    </Col>
                </FormGroup>
                <FormGroup row className="align-items-center bottomBreak">
                    <Col md={2}>
                        <Label for="businessJustification">Business Justification</Label>
                    </Col>
                    <Col md={9} className="col text-right">
                        <Input type="textarea" style={{ minHeight: 100 }} name="text" id="businessJustification" readOnly />
                    </Col>
                    <Col md={1} className="col text-center">

                        <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon />} >
                            Save
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={6}>
                        <h5><Label dark><b>Retail Group ID Ranges</b></Label></h5>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {retailRows.map(row => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map(column => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                    <Col md={6}>
                        <h5><Label><b>OEM Group ID Ranges</b></Label></h5>

                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {oemRows.map(row => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map(column => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </FormGroup>
            </Form>
        </Paper>
    );
}

export default PartNumber;