import React, { Component, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home/Home';
import './custom.css'
import PrePK2009 from './components/PrePK2009';
import PK2009 from './components/PK2009';
import { CssBaseline } from '@material-ui/core';
import AuthProvider from "./authProvider";
import PropTypes from "prop-types";
import UnauthorizedAccess from './components/UnauthorizedAccess/UnauthorizedAccess';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle'
import { Divider } from '@material-ui/core';
import WarningIcon from './Images/Warning.png';
import './App.css';

function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [invokedUseEffect, setInvokedUseEffect] = React.useState(false);

    useEffect(() => {
        if (props.sessionTimeOut !== null && !invokedUseEffect) {
            setInvokedUseEffect(true);
            if (props.sessionTimeOut > 0) {
                setTimeout(() => setOpen(true), props.sessionTimeOut);
            }
            else {
                setOpen(true);
            }
        }
    })

    const handleRefresh = () => {
        setOpen(false);
        sessionStorage.clear();
        window.location.reload();
    }

    const alertDialogBox = (
        <Dialog
            open={open}
            onClose={event => setOpen(false)}
            aria-labelledby="alert-dialog-title-main"
            aria-describedby="alert-dialog-description-main"
            style={{ fontFamily: '"Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"' }}
        >
            <DialogTitle id="alert-dialog-title-main">
                <img src={WarningIcon} className='warningicon' />
                Session Expired
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description-main">
                    Your session has expired, kindly refresh the page.
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions id="alert-dialog-description-main">
                <Button variant="contained" style={{
                    background: "#ff9800", textTransform: 'none',
                    fontFamily: '"Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    fontWidth: '400', fontSize: '1em'
                }}
                    onClick={handleRefresh} color="primary" autoFocus>
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>
    )
    return (<div>
        {alertDialogBox}
    </div>
    )
}

class App extends React.Component {
  static displayName = App.name;

    static propTypes = {
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired,
        idToken: PropTypes.string,
        accessToken: PropTypes.string,
        canPerformAction:PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            user: {},
            error: null
        };
    }
    componentDidMount() {
        console.log(this.props);
        if (this.props.account != null) {
            console.log('true')
            this.setState({
                isAuthenticated: true
            })
        }
        this.logOut();
    }
    logOut() {
    }
    render() {
        return (
            <React.Fragment>
                {this.props.isAuthorized && this.props.idToken != null ?
                    <Layout {...this.props} >
                        <Route exact path='/' render={(props) => <Home {...this.props} />} />
                        <Route path='/prePK2009' render={(props) => <PrePK2009 {...this.props} />} />
                        <Route path='/PK2009' render={(props) => <PK2009 {...this.props} />} />
                        <Route path='/unauthorizedAccess' render={(props) => <UnauthorizedAccess {...this.props} />} />
                    </Layout> : this.props.isAuthorized === false ?
                        <Route path='/' render={(props) => <UnauthorizedAccess {...this.props} />} />
                        : null
                }
                <AlertDialog sessionTimeOut={this.props.sessionTimeOut} />
            </React.Fragment>
        );
    }
}

export default AuthProvider(App);
