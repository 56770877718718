import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, TextField, FormControl, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../NewProject/NewProject';
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as XLSX from 'xlsx';
import './UploadPKSpec.css';
import '../NavBar';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { Container, Row, Col } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Backdrop, CircularProgress } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: 440,
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    pkpnTable: {
        width: '80%',
    },
    input: {
        display: 'none',
    },
}));

const columns = [
    { id: 'productName', label: 'Product Name', minWidth: 170 },
    {
        id: 'programPurpose',
        label: 'Program Purpose',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    {
        id: 'groupID',
        label: 'Group Id Working',
        minWidth: 170,
        align: 'right',
        format: value => value.toLocaleString(),
    },
    { id: 'keyType', label: 'Key Type', minWidth: 170 },
];

const rows = [
];

function createData(partNumber, startSerialNumber, endSerialNumber, description) {
    return { partNumber, startSerialNumber, endSerialNumber, description };
}

export default function UploadPKSpec(props) {

    const classes = useStyles();
    const [file, setFile] = React.useState();
    const [upload, setUpload] = React.useState(false);
    const [stream, setStream] = React.useState();
    const [ProjectLaunchId, setProjectLaunchId] = React.useState(props.projectID);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertBoxMessage, setAlertBoxMessage] = React.useState('');
    const [successfullUpload, setSuccessfullUpload] = React.useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleSave = () => {
    }

    const handleClose = () => {
        if (successfullUpload) {
            props.setNav(3);
        } else {
            setOpenAlert(false);
        }
    };

    const handleUpload = async (event) => {
        setFile(event.target.files[0]);
        setUpload(true);
        if (event.target.files[0]) {

            setOpenLoading(true);
            var uploadResponse = await uploadPkSpec(event.target.files[0], ProjectLaunchId);
            console.log(uploadResponse);
            setOpenLoading(false);
            if (uploadResponse.returnCode !== 0) {
                setAlertBoxMessage(uploadResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullUpload(false);
            }
            else if (uploadResponse.returnCode === 0) {
                setAlertBoxMessage(uploadResponse.returnMessage);
                setOpenAlert(true);
                setSuccessfullUpload(true);
            }
        }
    }

    const uploadButton = (
        <div className="division">
            <h2>Upload the PK Spec.</h2>
            <div className="uploadExcel">
                <div id="attachFileIcon">
                    <AttachFileIcon style={{ fontSize: "inherit" }} />
                </div>
                <div className="uploadText">
                    No PKSpec has been uploaded
                </div>
                <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className={classes.input}
                    id="uploadPkSpec"
                    onChange={handleUpload.bind(this)}
                    type="file"
                />
                <label htmlFor="uploadPkSpec">
                    <Button variant="contained" id="PPPButton" component="span" startIcon={<PublishIcon />} >
                        Upload
                    </Button>
                </label>
            </div>
        </div>
    );
    const addFiles = () => {
        console.log("Bleh");
        setUpload(true);
        if (file) {
            let data = new FormData();
            data.append('file', file);
            console.log(data.values);

            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = (file) => {

                var data = new Uint8Array(reader.result);
                const wb = XLSX.read(data, { type: 'array' });

            };
        }
    };

    const pkSpecDisplay = (
        <div className='division'>
            <h2>Upload PK Specs</h2>

            <label htmlFor="uploadPkSpec">
                <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className={classes.input}
                    id="uploadPkSpec"
                    onClick={addFiles.bind(this)}
                    type="file"
                />
                <Fab size="medium" color="#3f51b5" aria-label="add" component="span"><AddIcon /></Fab>
            </label>
            <h2 >Uploaded PK Specs.</h2>
            <div className="uploadsExcel">
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        id="TableHeader"
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map(column => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell id='PKSpecsTableBody' key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );

    return (
        <section id="contentProp-uploadPKSpec">
            <header role="heading" className="Header">
                <h1>Upload</h1>
                <h1 id="SubHeading">{props.productName}</h1>
            </header>
            <div id="contentProp-uploadPKSpec">
                {uploadButton}
                <div id="ButtonContainer">
                    <Button size="large" id="PPPButton" variant="contained" color="primary" disabled>Previous</Button>
                    <Button size="large" id="PPPButton" variant="contained" color="primary" onClick={handleSave.bind(this)}>Upload</Button>
                    <Button size="large" id="PPPButton" variant="contained" color="primary" onClick={event => props.setNav(6)} >Next</Button>
                </div>
                <Dialog
                    open={openAlert}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><div id="statusRepresentationAlert" style={successfullUpload ? { backgroundColor: '#097309' } : { backgroundColor: '#a90020' }} />{"Upload"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{alertBoxMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Close
                    </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop className={classes.backdrop} open={openLoading} >
                    <CircularProgress />
                </Backdrop>
            </div>
        </section>
    );
}

UploadPKSpec.propTypes = {
    setNav: PropTypes.func,
    projectID: PropTypes.number
}

async function uploadPkSpec(data, ProjectLaunchId) {
    const url = '/uploadpkspec';
    const formData = new FormData();
    formData.append('PkSpec', data);
    formData.append('ProjectLaunchId', ProjectLaunchId)
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    const response = await axios.post(url, formData, config);
    console.log(response.data);
    return await response.data;
}







