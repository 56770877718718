import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Header  from './Header/Header';
import { Footer } from './Footer/Footer';
import mslogo from '../Images/mslogo.png';
import './Home/Home.css'


export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Header {...this.props}/>
                {this.props.children}
                <Footer />
            </React.Fragment>
        );
    }
}
