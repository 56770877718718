import React, { Component } from 'react';
import './Home.css'
import HeroLogo from '../../Images/NewLogo.png';
import Constants from '../../ApplicationConstants';


export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        console.log(this.props);
    }

    render() {
        return (
            <section id="HeroBar" className="pagePadding">
                <div id="HeroFlexContainer">
                    <div id="HeroImage">
                        <img src={HeroLogo} alt="Hero Bar Image" />
                    </div>
                    <div id="HeroContent">
                        <h1>Welcome to PPP - The Microsoft Internal Product Release Website</h1>
                        <div className="buttons">
                            <a href="/prePK2009" >
                                <span role="button" onClick={() => {
                                    Constants.Version = "PID3";
                                    console.log(Constants);
                                }}  className="button HomePageButton">Pre PK 2009</span>{' '}
                            </a>
                            <a href="/PK2009" >
                                <span role="button" onClick={() => { Constants.Version = "PID6"; console.log(Constants); }} className="button HomePageButton" disabled>PK 2009</span>
                                </a>
                        </div>
                    </div>
                </div>
                <div id="HeroDisclaimerContainer">
                    <div id="HeroDisclaimer" role="note" aria-label="Disclaimer">* Product keys are Microsoft Assets traceable to you, you are responsible for reading and following the 'Product Key Security Guidelines' in the PPP FAQ to secure all product keys.</div>
                    <div id="HeroContact" role="note" aria-label="Disclaimer">**&nbsp;For installing software or other technical support issues with the product please contact <a className="ITdesk"role="link" href="https://microsoft.sharepoint.com/sites/itweb/" target="_blank">IT Helpdesk</a></div>
                </div>
            </section>
        );
    }
}


