import React, { useEffect } from 'react';
import './Home/Home.css'
import NavBar from './NavBar';
//import '../Constants.js';
import Constants from '../ApplicationConstants.js';

export default function PrePK2009(props) {

    return (
        <section className="MainContainer">
            <NavBar versionDetail={"SubClass3"} {...props} />
        </section>
    );
}