import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Header.css';
import { AppBar } from '@material-ui/core';

export default function Header(props) {

    return (
        <AppBar id="MuiAppBar-colorPrimary" className="border-bottom box-shadow pageHeader pagePadding">
            <div className="menuContent"  >
                <NavbarBrand tag={Link} to="/" ><h1>
                    <span className="headerPID">PID </span>
                    <span className="headerPlanningPipeline">Planning Pipeline</span>
                </h1></NavbarBrand>
            </div>
            <div className="loggedUser" tabIndex="-50" asp-controller="Account" asp-action="Login" style={{fontSize:'14px'}}>
                {props.account.name}
            </div>
            </AppBar>
    );
}

